import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import styled from "styled-components";

const UserAdvancementBox = styled.div`
  .chartjs-render-monitor {
    max-height: 300px;
    min-height: 300px;
  }
`;

const getRandomData = () =>
  new Array(12).fill(0).map(() => Math.floor(Math.random() * 100));

const getState = () => ({
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Auguest",
    "September",
    "October",
    "November",
    "December",
  ],
  datasets: [
    {
      label: "Users",
      backgroundColor: "#48b5ff",
      borderColor: "#48b5ff",
      borderWidth: 1,
      hoverBackgroundColor: "#48b5ff",
      hoverBorderColor: "#48b5ff",
      data: getRandomData(),
    },
  ],
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: "bottom",
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "rgb(204, 204, 204)",
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: "rgb(204, 204, 204)",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "rgb(204, 204, 204)",
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: "rgb(204, 204, 204)",
        },
      },
    ],
  },
};

const UserAdvancement = () => {
  const { t } = useTranslation("common");
  const [data, setData] = useState(getState());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setData(getState());
    }, 4000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>
              {"Member Level Advancement"}
            </CardTitle>
          </CardTitleWrap>
          <UserAdvancementBox>
            <Bar data={data} options={options} />
          </UserAdvancementBox>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserAdvancement;
