const BasicTableData = () => {
  const header = [
    { id: 1, title: "#" },
    { id: 2, title: "UserName" },
    { id: 3, title: "Content" },
    { id: 4, title: "Rating" },
  ];

  const headerResponsive = [
    { id: 1, title: "#" },
    { id: 2, title: "UserName" },
    { id: 3, title: "Preferred Personas" },
    { id: 3, title: "Message" },
    { id: 4, title: "Preferred Settings" },
  ];

  const SurveysHeader = [
    { id: 1, title: "#" },
    { id: 2, title: "UserName" },
    { id: 3, title: "CSAT Score" },
    { id: 3, title: "NPS Score" },
    { id: 4, title: "CES Score" },
  ];

  const rows = [
    {
      id: 1,
      username: "Mario1",
      content: "This is amazing program",
      rating: "4.5",
      message: "56",
      personas: "Sport",
      setting: "Dark mode, English",
      csat: "5",
      nps: "4.6",
      ces: "4",
    },
    {
      id: 2,
      username: "Mario2",
      content: "This is amazing program",
      rating: "3.5",
      message: "56",
      personas: "Sport",
      setting: "Dark mode, English",
      csat: "5",
      nps: "4.6",
      ces: "4",
    },
    {
      id: 3,
      username: "Mario3",
      content: "This is amazing program",
      rating: "5",
      message: "56",
      personas: "Sport",
      setting: "Dark mode, English",
      csat: "5",
      nps: "4.6",
      ces: "4",
    },
    {
      id: 4,
      username: "Mario4",
      content: "This is amazing program",
      rating: "4",
      message: "56",
      personas: "Sport",
      setting: "Dark mode, English",
      csat: "5",
      nps: "4.6",
      ces: "4",
    },
    {
      id: 5,
      username: "Mario5",
      content: "This is amazing program",
      rating: "4.3",
      message: "56",
      personas: "Sport",
      setting: "Dark mode, English",
      csat: "5",
      nps: "4.6",
      ces: "4",
    },
    {
      id: 6,
      username: "Mario6",
      content: "This is amazing program",
      rating: "4.2",
      message: "56",
      personas: "Sport",
      setting: "Dark mode, Germany",
      csat: "5",
      nps: "4.6",
      ces: "4",
    },
    {
      id: 7,
      username: "Mario7",
      content: "Wonderful!",
      rating: "4.9",
      message: "56",
      personas: "Sport",
      setting: "Dark mode, English",
      csat: "5",
      nps: "4.6",
      ces: "4",
    },
  ];

  const basicTableData = {
    tableHeaderData: header,
    SurveysHeaderData: SurveysHeader,
    tableHeaderResponsiveData: headerResponsive,
    tableRowsData: rows,
  };
  return basicTableData;
};

export default BasicTableData;
