import { useEffect, useRef } from "react";
import { useCallback, useState } from "react";
import {
  HiPaperAirplane,
  HiPlayCircle,
  HiStopCircle,
  HiTrash,
} from "react-icons/hi2";
import { IoMdImages, IoMdCloseCircle } from "react-icons/io";

import { MdOutlineEmojiEmotions } from "react-icons/md";
import { BsSoundwave } from "react-icons/bs";
import styled from "styled-components";
import FilePreviewIcon from "./FilePreviewIcon";
import { useAudioRecorder } from "react-audio-voice-recorder";
import toWav from "audiobuffer-to-wav";
import {
  arrayBufferToAudioBuffer,
  blobToArrayBuffer,
} from "../../../utils/helpers";

export default function ChatInput({ onSendQuestion, isLoading }) {
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();
  const hiddenInputRef = useRef();

  const [showSendIcon, setShowSendIcon] = useState(false);
  const [question, setQuestion] = useState("");
  const [fileList, setFileList] = useState([]);
  const [audioFile, setAudioFile] = useState({
    file: null,
    shouldRemove: false,
  });

  const handleSendMessage = useCallback(() => {
    setQuestion("");
    setFileList([]);
    onSendQuestion(question, fileList);
  }, [question, fileList]);

  // Modify handleSendAudio function
  const handleSendAudio = useCallback(async () => {
    try {
      const arrayBuffer = await blobToArrayBuffer(audioFile.file);
      const audioBuffer = await arrayBufferToAudioBuffer(arrayBuffer);
      const wavBlob = new Blob([new DataView(toWav(audioBuffer))], {
        type: "audio/wav",
      });

      setAudioFile({
        file: null,
        shouldRemove: true,
      });
      onSendQuestion("", [], wavBlob);
    } catch (error) {
      console.error("Error converting blob to WAV:", error);
    }
  }, [audioFile.file, setAudioFile]);

  const handleKeyUp = useCallback(
    (e) => {
      if (e.keyCode === 13 && !isLoading) {
        handleSendMessage();
      }
    },
    [handleSendMessage],
  );

  const handleFileUpload = useCallback((e) => {
    setFileList((prev) => [...prev, ...e.target.files]);

    e.target.value = "";
  }, []);

  const convertNumberToTimeString = useCallback((number) => {
    // calculate hours and minutes
    const hours = Math.floor(number / 60);
    const minutes = number % 60;

    // convert to string and pad with zeros if necessary
    const hoursString = String(hours).padStart(2, "0");
    const minutesString = String(minutes).padStart(2, "0");

    return `${hoursString}:${minutesString}`;
  }, []);

  const handleAudioChange = useCallback(() => {
    if (recordingBlob) {
      if (!audioFile.shouldRemove && !audioFile.file) {
        setAudioFile({
          shouldRemove: false,
          file: recordingBlob,
        });
      }
    }
  }, [recordingBlob, audioFile]);

  useEffect(() => {
    if (question.length > 0) {
      setShowSendIcon(true);
    } else {
      setShowSendIcon(false);
    }
  }, [question]);

  useEffect(() => {
    if (fileList.length > 0) {
      setShowSendIcon(true);
    } else {
      setShowSendIcon(false);
    }
  }, [fileList]);

  useEffect(() => {
    if (!recordingBlob) return;

    handleAudioChange();
    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [recordingBlob, handleAudioChange]);

  useEffect(() => {
    if (audioFile.file) {
      handleSendAudio();
    }
  }, [audioFile.file, handleSendAudio]);

  return (
    <StyledChatInput>
      <StyledInputWrapper>
        <StyledImageWrapper>
          {fileList.map((item, index) => (
            <FilePreviewIcon
              file={item}
              key={index}
              onFileRemove={() =>
                setFileList((prev) => prev.filter((item, it) => it !== index))
              }
            />
          ))}
        </StyledImageWrapper>
        <Input
          value={question}
          onKeyUp={handleKeyUp}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter your message..."
        />
        {showSendIcon && (
          <StyledHiPaperAirplane size={20} onClick={handleSendMessage} />
        )}
      </StyledInputWrapper>
      <StyledMetaWrapper>
        <StyledIconWrapper>
          {/* <MdOutlineEmojiEmotions
            size={24}
            color="#92a9c1"
            style={{ cursor: "pointer" }}
          /> */}
          <IoMdImages
            size={24}
            color="#92a9c1"
            style={{ cursor: "pointer" }}
            onClick={() => hiddenInputRef.current?.click()}
          />
          {!isRecording ? (
            <BsSoundwave
              size={24}
              color="#92a9c1"
              style={{ cursor: "pointer" }}
              onClick={startRecording}
            />
          ) : (
            <StyledAudioRecordWrapper>
              <StyledRecordMainWrapper isPaused={isPaused}>
                {isPaused ? (
                  <HiPlayCircle
                    color="white"
                    size={18}
                    style={{ cursor: "pointer" }}
                    onClick={togglePauseResume}
                  />
                ) : (
                  <HiStopCircle
                    color="white"
                    size={18}
                    style={{ cursor: "pointer" }}
                    onClick={togglePauseResume}
                  />
                )}
                <p style={{ lineHeight: "12px" }}>
                  {convertNumberToTimeString(recordingTime)}
                </p>
              </StyledRecordMainWrapper>
              <HiTrash
                color="#ee334b"
                size={20}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAudioFile((prev) => ({ ...prev, shouldRemove: true }));
                  stopRecording();
                }}
              />
              <HiPaperAirplane
                color="#ee334b"
                size={20}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAudioFile((prev) => ({ ...prev, shouldRemove: false }));
                  stopRecording();
                }}
              />
            </StyledAudioRecordWrapper>
          )}
        </StyledIconWrapper>
        <div style={{ color: "#92a9c1" }}>
          Powered by{" "}
          <a
            style={{ color: "#7f95ab" }}
            target="_blank"
            href="https://vengoai.com"
            rel="noreferrer"
          >
            <b>VengoAI</b>
          </a>
        </div>
        <input
          style={{ display: "none" }}
          ref={hiddenInputRef}
          type="file"
          onChange={handleFileUpload}
          multiple
          accept="image/*"
        />
      </StyledMetaWrapper>
    </StyledChatInput>
  );
}

const StyledChatInput = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: white;
  border-top-width: 1px;
  border-color: black;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 100%;
  font-size: 16px;
  border: none !important;
  outline: none !important;
  padding-right: 30px;
  padding: 12px 50px 12px 20px;

  &::placeholder {
    color: #92a9c1;
  }
`;

const StyledHiPaperAirplane = styled(HiPaperAirplane)`
  position: absolute;
  right: 24px;
  bottom: 16px;
  cursor: pointer;
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledMetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px 8px 20px;
  justify-content: space-between;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledImageWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 4px;
  top: -52px;
  width: 100%;
  padding: 0px 12px;
`;

const StyledAudioRecordWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledRecordMainWrapper = styled.div`
  display: flex;
  gap: 2px;
  background-color: #ee334b;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;

  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: ${(props) => (props.isPaused ? 1 : 0.8)};
    }
  }
`;
