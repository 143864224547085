import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colorBorder, colorBackground, colorHover } from "@/utils/palette";
import { left } from "@/utils/directions";
import SidebarLink, { SidebarLinkTitle } from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

const SidebarContent = ({ onClick, changeToLight, changeToDark, collapse }) => {
  const [side, setSide] = useState([]);
  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  useEffect(() => {
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setSide(result.data.role_permissions);
    });
  }, []);

  if (!side.length) return <></>;

  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={collapse}>
        <>
          {side.length !== 0 &&
            (side[0].delete || side[0].read || side[0].write) && (
              <SidebarLink
                title="Analytics"
                icon="pie-chart"
                route="/"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[1].delete || side[1].read || side[1].write) && (
              <SidebarLink
                title="AI Training"
                icon="microchip"
                route="/ai_training"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[2].delete || side[2].read || side[2].write) && (
              <SidebarLink
                title="Base Persona"
                icon="microchip"
                route="/basepersona"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[3].delete || side[3].read || side[3].write) && (
              <SidebarLink
                title="Members"
                icon="user"
                route="/members"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[4].delete || side[4].read || side[4].write) && (
              <SidebarLink
                title="Message Metrics"
                icon="comments"
                route="/message_metrics"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[5].delete || side[5].read || side[5].write) && (
              <SidebarLink
                title="AI Metrics"
                icon="pie-chart"
                route="/ai_metrics"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[6].delete || side[6].read || side[6].write) && (
              <SidebarLink
                title="Level Tracker"
                icon="line-chart"
                route="/level_tracker"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[7].delete || side[7].read || side[7].write) && (
              <SidebarLink
                title="Purchases"
                icon="usd"
                route="/purchases"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[8].delete ||
              side[8].read ||
              side[8].write ||
              side[9].delete ||
              side[9].read ||
              side[9].write ||
              side[10].delete ||
              side[10].read ||
              side[10].write) && (
              <SidebarCategory
                title="Attributes"
                icon="tags"
                onClick={onClick}
                collapse={collapse}
              >
                <>
                  {side.length !== 0 &&
                    (side[8].delete || side[8].read || side[8].write) && (
                      <SidebarLink
                        title="Wallpapers"
                        route="/wallpapers"
                        onClick={onClick}
                      />
                    )}
                </>
                <>
                  {side.length !== 0 &&
                    (side[9].delete || side[9].read || side[9].write) && (
                      <SidebarLink
                        title="Interests"
                        route="/interests"
                        onClick={onClick}
                      />
                    )}
                </>
                <>
                  {side.length !== 0 &&
                    (side[10].delete || side[10].read || side[10].write) && (
                      <SidebarLink
                        title="Identities"
                        route="/identities"
                        onClick={onClick}
                      />
                    )}
                </>
              </SidebarCategory>
            )}
        </>

        <>
          {side.length !== 0 &&
            (side[11].delete || side[11].read || side[11].write) && (
              <SidebarLink
                title="User Feedback"
                icon="comment"
                route="/userfeedback"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[12].delete || side[12].read || side[12].write) && (
              <SidebarLink
                title="Admins"
                icon="users"
                route="/admins"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[13].delete || side[13].read || side[13].write) && (
              <SidebarLink
                title="Access Controls"
                icon="lock"
                route="/access_controls"
                onClick={onClick}
              />
            )}
        </>
        <>
          {side.length !== 0 &&
            (side[14].delete || side[14].read || side[14].write) && (
              <SidebarLink
                title="Global Messages"
                icon="comment"
                route="/global_messages"
                onClick={onClick}
              />
            )}
        </>
        <SidebarLink
          title="Questions"
          icon="comment"
          route="/questions"
          onClick={onClick}
        />
        <SidebarLink
          title="Embedding UI"
          icon="microchip"
          route="/embedding_ui"
          onClick={onClick}
        />
      </SidebarBlock>
    </SidebarContentWrap>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => {},
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
      props.collapse &&
      `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

// endregion
