import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import toast from "react-hot-toast";
import adminData from "./adminData";
import EditAdminModal from "./components/EditAdminModal";
import useFullLoading from "../../shared/hooks/store/useLoading";
import { Table } from "@/shared/components/TableElements";
import Badge from "@/shared/components/Badge";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import AccountCancelIcon from "mdi-react/AccountCancelIcon";
import AccountCheckIcon from "mdi-react/AccountCheckIcon";
import FileEditOutlineIcon from "mdi-react/FileEditOutlineIcon";
import AccountKeyIcon from "mdi-react/AccountKeyIcon";
import PasswordModal from "./components/PasswordModal";
import DeleteModal from "../Members/components/DeleteModal";

const ManageAdmins = () => {
  const { setLoading } = useFullLoading();
  const { tableHeaderData } = adminData();
  const [openModal, setOpenModal] = useState(false);
  const [openPassModal, setOpenPassModal] = useState(false);
  const [editData, setEditData] = useState();
  const [adminList, setAdminLits] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [disable, setDisable] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleApproveManage = async (id) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/admin_manage/approve/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            toast.success("success!");
            await handleFetchManageAdmin();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const handleBlockManage = async (id) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/admin_manage/block/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            toast.success("success!");
            await handleFetchManageAdmin();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const handleDeleteManage = async (id) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/admin_manage/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            toast.success("success!");
            await handleFetchManageAdmin();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const actionColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <FileEditOutlineIcon
          onClick={() => {
            if (disable.length)
              if (disable[12].update) {
                setEditData(data);
                setIsCreate(false);
                setOpenModal(true);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[12].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[12].update ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <AccountCheckIcon
          onClick={() => {
            if (disable.length)
              if (disable[12].update) {
                handleApproveManage(data.id);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[12].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[12].update ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <AccountCancelIcon
          onClick={() => {
            if (disable.length)
              if (disable[12].update) {
                handleBlockManage(data.id);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[12].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[12].update ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <AccountKeyIcon
          onClick={() => {
            if (disable.length)
              if (disable[12].update) {
                setEditData(data);
                setOpenPassModal(true);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[12].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[12].update ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <TrashOutlineIcon
          onClick={() => {
            if (disable.length)
              if (disable[12].delete) {
                setOpenDeleteModal(true);
                setDeleteId(data.id);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[12].delete
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[12].delete ? 1 : 0.5) : "",
          }}
          role="button"
        />
      </div>
    ),
    [handleApproveManage, handleBlockManage, disable],
  );

  const statusColumn = (data) => {
    let temp = data.status ?? 1;
    if (temp === 1) {
      return <Badge bg={"primary"}>Approved</Badge>;
    } else {
      return <Badge bg={"secondary"}>Blocked</Badge>;
    }
  };

  const tableData = useMemo(
    () =>
      adminList &&
      adminList.map((item, index) => ({
        ...item,
        no: index + 1,
        status: statusColumn(item),
        action: actionColumn(item),
      })),
    [adminList, statusColumn],
  );

  const handleFetchManageAdmin = useCallback(async () => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/admin_manage/get_admins`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            setAdminLits(res.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  }, [setLoading]);

  const handleSaveAdmin = useCallback(
    async (data) => {
      let token = localStorage.getItem("token");
      setOpenModal(false);
      setLoading(true);
      const payload = editData
        ? {
            email: data.email,
            name: data.name,
            role: data.role.value,
            status: data.status,
          }
        : { ...data, role: data.role.value };
      try {
        const res = editData
          ? fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/admin_manage/update/${editData.id}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              },
            )
              .then((res) => res.json())
              .then(async (res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  await handleFetchManageAdmin();
                }
              })
              .finally(() => {
                setLoading(false);
              })
          : fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/admin_manage/create`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              },
            )
              .then((res) => res.json())
              .then(async (res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  await handleFetchManageAdmin();
                }
              })
              .finally(() => {
                setLoading(false);
              });
        setIsCreate(true);
        setEditData(null);
      } catch (err) {
        toast.error(err);
      }
    },
    [editData, setLoading, handleFetchManageAdmin],
  );

  const handleSavePassword = useCallback(
    async (data) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/admin_manage/change_password/${editData.id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          },
        )
          .then((res) => res.json())
          .then(async (res) => {
            if (res.status === "success") {
              toast.success("Password has been changed successfully!");
            }
          })
          .finally(() => {
            setLoading(false);
          });
        setOpenPassModal(false);
        setEditData(null);
      } catch (err) {
        toast.error(err);
      }
    },
    [setLoading],
  );

  const handleFetchRoleList = useCallback(async () => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/role_manage/get_roles`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            setRoleList([
              ...res.data.map((item) => ({
                value: item.id,
                label: item.title,
              })),
            ]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  }, [setLoading]);

  useEffect(() => {
    handleFetchManageAdmin();
    handleFetchRoleList();
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setDisable(result.data.role_permissions);
    });
  }, []);

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Card>
          <CardBody>
            <StyledHeader>
              <CardTitleWrap>
                <CardTitle>Admins</CardTitle>
                <CardSubhead>Manage Admin</CardSubhead>
              </CardTitleWrap>
              <StyledButton
                variant="primary"
                onClick={() => {
                  if (disable.length) if (disable[12].write) setOpenModal(true);
                }}
                style={{
                  cursor: disable.length
                    ? disable[12].write
                      ? "pointer"
                      : "not-allowed"
                    : "",
                  opacity: disable.length ? (disable[12].write ? 1 : 0.5) : "",
                }}
              >
                Add New Admin
              </StyledButton>
            </StyledHeader>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        {tableHeaderData.map((item) => (
                          <th key={item.id}>{item.Header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.no}</td>
                            <td>{item.email}</td>
                            <td>{item.name}</td>
                            <td>{item.role_title}</td>
                            <td>{item.status}</td>
                            <td>{item.action}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </Row>
      <EditAdminModal
        title={editData ? "Edit Admin" : "Add Admin"}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
          setIsCreate(true);
        }}
        roleList={roleList}
        onSubmit={handleSaveAdmin}
        editData={editData}
        isCreate={isCreate}
      />
      <PasswordModal
        title="Change Password"
        open={openPassModal}
        onClose={() => {
          setOpenPassModal(false);
          setEditData(null);
        }}
        onSubmit={handleSavePassword}
      />
      <DeleteModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteManage(deleteId);
        }}
        title={"Are you sure you want to delete this admin?"}
      />
    </Container>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
  margin-bottom: 30px;
`;

export default ManageAdmins;
