import styled from "styled-components";

export default function ChatHeader({ persona }) {
  if (!persona?.header_text_color) {
    return <HeaderLoadingSkeleton />;
  }

  return (
    <ChatHeaderStyle
      color={persona.header_text_color}
      bgColor={persona.header_bg_color}
    >
      {persona && (
        <>
          <StyledImage src={persona.persona_picture} alt="Persona" />
          {persona.name}
        </>
      )}
    </ChatHeaderStyle>
  );
}

const HeaderLoadingSkeleton = styled.div`
  height: 60px;
  width: 100%;
  background: black;
`;

const ChatHeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  background: ${(props) => props.bgColor};
  height: 60px;
  align-items: center;
  padding-left: 20px;
  color: ${(props) => props.color};
  font-size: medium;
  gap: 8px;
`;

const StyledImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
`;
