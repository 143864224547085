import React, { useEffect, useState } from "react";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { FormField } from "@/containers/Form/ReactHookForm/FormHookValidation/components/FormField";
import renderDropZoneField from "@/shared/components/form/dropzones/DropZone";
import { FileInputField } from "@/shared/components/form/FileInput";
import { useForm, Controller } from "react-hook-form";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import ProgressBar from "@/shared/components/ProgressBar";

const CreatePersona = ({
  onSubmit,
  interestOption,
  progress,
  createLoading,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [view, setView] = useState(false);
  const [tag, setTag] = useState("");
  const [urls, setUrls] = useState([]);
  const [trainProgress, setTrainProgress] = useState(0);

  const removeUrl = (id) => {
    const updatedUrls = urls.filter((url, index) => index !== id);
    setUrls(updatedUrls);
  };

  const handleUrlChange = (id, value) => {
    const updatedUrls = urls.map((url, index) => {
      if (index === id) {
        return value;
      }
      return url;
    });
    setUrls(updatedUrls);
  };

  const onSubmitForm = (data) => {
    data.interest = tag;
    data.url = urls;
    onSubmit(data);
  };

  useEffect(() => {
    if (progress !== undefined) {
      setTrainProgress(progress);
    }
  }, [progress]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmitForm)}>
      <ImageStyle>
        <div className="w-50">
          <FormGroup>
            <FormField
              name="files"
              component={renderDropZoneField}
              control={control}
              customHeight
            />
          </FormGroup>
        </div>
      </ImageStyle>
      <FormGroup>
        <FormGroupLabel>Name</FormGroupLabel>
        <FormGroupField>
          <FormField
            name="name"
            control={control}
            component="input"
            errors={errors}
            rules={{ required: "This is required field" }}
            defaultValue=""
            placeholder="e.g.Alex Chen"
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Bio</FormGroupLabel>
        <FormGroupField>
          <FormField
            name="bio"
            control={control}
            component="textarea"
            errors={errors}
            rules={{
              required: "This is required field",
              minLength: {
                value: 50,
                message: "Minimum character length is 50 characters",
              },
            }}
            defaultValue=""
            placeholder="e.g. “Alex, with her Taiwanese roots, brings a vibrant flair to the gaming world. Specializing in a range of genres, from adrenaline-fueled FPS games to richly detailed RPGs, she's adept at elevating players' skills, whether they're casual enthusiasts or aspiring eSports professionals. Alex combines strategic gameplay with character development insights, offering a holistic approach to gaming. Join her for an immersive journey into the gaming universe, where every session promises to be a leap towards mastery.”"
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Personality Pre-Prompt</FormGroupLabel>
        <a
          href="https://docs.google.com/document/d/10dPXZziYxWrHGGZr3gv_ByacA8kJixBaUFZNFS-SehA/edit?usp=sharing"
          target="_blank"
        >
          {"  "}Click here to see an example
        </a>
        <FormGroupField>
          <FormField
            name="personality"
            control={control}
            component="textarea"
            errors={errors}
            rules={{
              required: "This is required field",
              minLength: {
                value: 50,
                message: "Minimum character length is 50 characters",
              },
            }}
            defaultValue=""
            placeholder="Create a character profile for the AI persona, detailing their expertise, personality, and user interaction. Focus on areas like type of knowledge, enthusiasm, and vocabulary style. Your aim is to clearly represent the persona's traits and how they engage with users. For example, for a Gaming Expert and Coach, highlight their extensive gaming knowledge, list of skills, passion for gaming, and personalized coaching approach."
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Public Website URLs</FormGroupLabel>
        <label className="text-secondary">
          Paste in as many links as you want to website pages, news articles,
          blog posts, Wikipedia pages, or any other public web page you think
          will be useful in training your Persona. Paste one link per line.
        </label>
        <FormGroupField>
          <UrlsGroup>
            {urls &&
              urls.map((url, index) => (
                <UrlInput>
                  <input
                    type="text"
                    onChange={(e) => handleUrlChange(index, e.target.value)}
                  />
                  <TrashOutlineIcon
                    className="delete-style"
                    onClick={() => removeUrl(index)}
                  />
                </UrlInput>
              ))}
          </UrlsGroup>
        </FormGroupField>
        <AddUrlButton className="mt-2" onClick={() => setUrls([...urls, ""])}>
          + Add Urls
        </AddUrlButton>
      </FormGroup>
      <FormGroup>
        <TagLabelStyle>
          <FormGroupLabel>Choose a Category</FormGroupLabel>
          {view ? (
            <div
              className="text-secondary cursor-point"
              onClick={() => setView(false)}
            >
              View Less
            </div>
          ) : (
            <div
              className="text-secondary cursor-point"
              onClick={() => setView(true)}
            >
              View All
            </div>
          )}
        </TagLabelStyle>
        <FormGroupField>
          <TagGroup>
            {interestOption &&
              (view ? interestOption : interestOption.slice(0, 10)).map(
                (interest) => {
                  if (interest.value === tag) {
                    return (
                      <TagSelect onClick={() => setTag(interest.value)}>
                        {interest.label}
                      </TagSelect>
                    );
                  } else {
                    return (
                      <Tag onClick={() => setTag(interest.value)}>
                        {interest.label}
                      </Tag>
                    );
                  }
                },
              )}
          </TagGroup>
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Knowledge Base</FormGroupLabel>
        <label className="text-secondary">
          Here's where you upload all of the information you have about your
          persona to train it. i.e. Wikipedia pages, books about certain topics,
          interviews, news articles, tweets, or other public information(PDF,
          DOC, TXT 10MB limit per file).
        </label>
        <FormGroupField>
          <Controller
            name="knowledge"
            control={control}
            render={({ field }) => <FileInputField isMulti {...field} />}
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Questions & Answers</FormGroupLabel>
        <label className="text-secondary">
          Upload questions a human might ask your persona along with the answers
          your persona would give, to train it. i.e. Question: What is your
          favorite outdoor activity and why? Answer: I like to go on walks
          because it is relaxing, and I can clear my mind. or Question: What is
          your favorite quote and why? Answer: “Carpe Diem” means to seize the
          day, which is my approach to life.{" "}
          <a
            href="https://docs.google.com/document/d/1DotPI3MDbEurTjWxhR58n2wjEZpfxtq_Mx3MBlyUThE/edit?usp=drive_link"
            target="_blank"
          >
            Click here to see a template.{" "}
          </a>
          (PDF, DOC, TXT 10MB limit per file).
        </label>
        <FormGroupField>
          <Controller
            name="question"
            control={control}
            rules={{ required: "This is required field" }}
            render={({ field }) => <FileInputField {...field} multiple />}
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Training Progress</FormGroupLabel>
        <FormGroupField>
          <div className="w-100">
            <ProgressBar
              now={trainProgress}
              label={`${trainProgress}%`}
              size="middle"
            />
          </div>
        </FormGroupField>
      </FormGroup>
      <div>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            setValue("name", "");
            setValue("bio", "");
            setValue("personality", "");
            setTag("");
            setValue("knowledge", []);
            setValue("question", []);
            setValue("files", "");
            setUrls([]);
            setValue("option", false);
            setTrainProgress(0);
          }}
        >
          Reset
        </Button>
        <Button variant="primary" type="submit">
          Save
        </Button>
        {createLoading ? (
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </FormContainer>
  );
};

const ImageStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TagLabelStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TagGroup = styled.div`
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Tag = styled.div`
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`;

const TagSelect = styled.div`
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  cursor: pointer;
  background-color: white;
`;

const AddUrlButton = styled.div`
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  cursor: pointer;
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
`;

const UrlsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 75%;
`;

const UrlInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export default CreatePersona;
