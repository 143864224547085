import React, { useEffect, useState } from "react";
import { Button } from "@/shared/components/Button";
import { Col, Row, Container } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import styled from "styled-components";
import Input from "../../../shared/components/Input";
import { BiSolidMessageRounded } from "react-icons/bi";
import toast from "react-hot-toast";
import useFullLoading from "../../../shared/hooks/store/useLoading";

const EditStyle = ({ personaInfo }) => {
  const { setLoading } = useFullLoading();
  const [headingTitleColor, setHeadingTitleColor] = useState("#ffffff");
  const [headingBackColor, setHeadingBackColor] = useState("#ff0000");
  const [firstMsg, setFirstMsg] = useState("Hi");
  const [botMsgColor, setBotMsgColor] = useState("#232329");
  const [botMsgBackColor, setBotMsgBackColor] = useState("#00ff2b");
  const [userMsgColor, setUserMsgColor] = useState("#232329");
  const [userMsgBackColor, setUserMsgBackColor] = useState("#00ff2b");
  const [widgetColor, setWidgetColor] = useState("#ff0000");

  const handleFetchStyle = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/embed_manage/settings`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          persona_id: personaInfo.id,
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setBotMsgBackColor(res.data.ai_bg_color);
        setBotMsgColor(res.data.ai_text_color);
        setWidgetColor(res.data.badge_bg_color);
        setFirstMsg(res.data.first_message);
        setHeadingBackColor(res.data.header_bg_color);
        setHeadingTitleColor(res.data.header_text_color);
        setUserMsgBackColor(res.data.human_bg_color);
        setUserMsgColor(res.data.human_text_color);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeStyle = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/embed_manage/update_settings`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          persona_id: personaInfo.id,
          human_text_color: userMsgColor,
          human_bg_color: userMsgBackColor,
          first_message: firstMsg,
          ai_text_color: botMsgColor,
          ai_bg_color: botMsgBackColor,
          header_text_color: headingTitleColor,
          header_bg_color: headingBackColor,
          badge_bg_color: widgetColor,
        }),
      },
    )
      .then((res) => res.json())
      .then(async (res) => {
        toast.success("success!");
        await handleFetchStyle();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleFetchStyle();
  }, [personaInfo]);

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <div>
                <label for="heading_title_color" class="form-label">
                  Heading title color:
                </label>
                <Input
                  type="color"
                  class="form-control form-control-color"
                  id="heading_title_color"
                  value={headingTitleColor}
                  title="Choose your color"
                  onChange={(e) => {
                    setHeadingTitleColor(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="heading_background_color" class="form-label">
                  Heading background color:
                </label>
                <Input
                  type="color"
                  class="form-control form-control-color"
                  id="heading_background_color"
                  value={headingBackColor}
                  title="Choose your color"
                  onChange={(e) => {
                    setHeadingBackColor(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="first_message" class="form-label">
                  First Message:
                </label>
                <Input
                  id="first_message"
                  type="text"
                  value={firstMsg}
                  placeholder="e.g.Hi"
                  onChange={(e) => {
                    setFirstMsg(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="bot_message_color" class="form-label">
                  Bot message color:
                </label>
                <Input
                  type="color"
                  class="form-control form-control-color"
                  id="bot_message_color"
                  value={botMsgColor}
                  title="Choose your color"
                  onChange={(e) => {
                    setBotMsgColor(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="bot_message_background_color" class="form-label">
                  Bot message background color:
                </label>
                <Input
                  type="color"
                  class="form-control form-control-color"
                  id="bot_message_background_color"
                  value={botMsgBackColor}
                  title="Choose your color"
                  onChange={(e) => {
                    setBotMsgBackColor(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="user_message_color" class="form-label">
                  User message color:
                </label>
                <Input
                  type="color"
                  class="form-control form-control-color"
                  id="user_message_color"
                  value={userMsgColor}
                  title="Choose your color"
                  onChange={(e) => {
                    setUserMsgColor(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="user_message_background_color" class="form-label">
                  User message background color:
                </label>
                <Input
                  type="color"
                  class="form-control form-control-color"
                  id="user_message_background_color"
                  value={userMsgBackColor}
                  title="Choose your color"
                  onChange={(e) => {
                    setUserMsgBackColor(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="widget_color" class="form-label">
                  Widget color:
                </label>
                <Input
                  type="color"
                  class="form-control form-control-color"
                  id="widget_color"
                  value={widgetColor}
                  title="Choose your color"
                  onChange={(e) => {
                    setWidgetColor(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="primary"
                  className="mb-0 mt-3 p-2"
                  onClick={() => handleChangeStyle()}
                >
                  Update
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <WidgetBodyStyle>
                <WidgetHeaderStyle
                  headingBackColor={headingBackColor}
                  headingTitleColor={headingTitleColor}
                >
                  <HeaderTitleStyle>
                    <Avatar>
                      <img
                        src={`${personaInfo.persona_picture}?v=${Date.now()}`}
                        style={{ width: "35px" }}
                        alt=""
                      />
                    </Avatar>
                    {personaInfo.name}
                  </HeaderTitleStyle>
                </WidgetHeaderStyle>
                <WidgetContentStyle>
                  <BotMsgStyle>
                    <Avatar>
                      <img
                        src={`${personaInfo.persona_picture}?v=${Date.now()}`}
                        style={{ width: "35px" }}
                        alt=""
                      />
                    </Avatar>
                    <WidgetBotMsgStyle
                      botMsgColor={botMsgColor}
                      botMsgBackColor={botMsgBackColor}
                    >
                      {firstMsg}
                    </WidgetBotMsgStyle>
                  </BotMsgStyle>
                  <WidgetUserMsgStyle
                    userMsgBackColor={userMsgBackColor}
                    userMsgColor={userMsgColor}
                  >
                    How are you?
                  </WidgetUserMsgStyle>
                </WidgetContentStyle>
              </WidgetBodyStyle>
              <WidgetStyle widgetColor={widgetColor}>
                <BiSolidMessageRounded size={35} color="white" />
              </WidgetStyle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const WidgetStyle = styled.div`
   {
    max-width: 50px;
    max-height: 50px;
    border-radius: 55px;
    background: ${(props) => props.widgetColor};
    padding: 8px;
    position: absolute;
    bottom: 110px;
    right: 35px;
  }
`;

const WidgetBodyStyle = styled.div`
   {
    background-color: white;
    min-height: 390px;
    border-radius: 10px;
    margin-top: 45px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const WidgetHeaderStyle = styled.div`
   {
    padding: 16px;
    background-color: ${(props) => props.headingBackColor};
    color: ${(props) => props.headingTitleColor};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 16px;
  }
`;

const WidgetContentStyle = styled.div`
   {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }
`;

const WidgetBotMsgStyle = styled.div`
   {
    background-color: ${(props) => props.botMsgBackColor};
    width: fit-content;
    padding: 6px;
    border-radius: 6px;
    color: ${(props) => props.botMsgColor};
    word-break: break-all;
  }
`;

const WidgetUserMsgStyle = styled.div`
   {
    background-color: ${(props) => props.userMsgBackColor};
    width: fit-content;
    padding: 6px;
    border-radius: 6px;
    margin-top: 10px;
    right: 36px;
    position: absolute;
    color: ${(props) => props.userMsgColor};
  }
`;

const HeaderTitleStyle = styled.div`
   {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 600;
  }
`;

const BotMsgStyle = styled.div`
   {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
`;

const Avatar = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  overflow: hidden;
  border-radius: 50%;
  display: none;

  img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 425px) {
    display: block;
  }
`;

export default EditStyle;
