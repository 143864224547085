import React, { useState } from "react";
import styled from "styled-components";
import PaperclipIcon from "mdi-react/PaperclipIcon";
import EmoticonIcon from "mdi-react/EmoticonIcon";
import {
  colorAccent,
  colorAdditional,
  colorBackgroundBody,
  colorFieldsBorder,
  colorText,
} from "@/utils/palette";
import {
  right,
  marginLeft,
  paddingRight,
  paddingLeft,
} from "@/utils/directions";
import PropTypes from "prop-types";

const ChatField = ({ handleMessage }) => {
  const [message, setMessage] = useState("");
  return (
    <ChatTextField>
      <ChatForm>
        <ChatFieldInput
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              handleMessage(message);
              setMessage("");
            }
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          type="text"
          name="chatField"
          placeholder="Type here…"
        />
        {/* <ChatFieldControls>
          <ChatIconButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <EmoticonIcon />
          </ChatIconButton>
          <ChatIconButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <PaperclipIcon />
          </ChatIconButton>
        </ChatFieldControls> */}
      </ChatForm>
    </ChatTextField>
  );
};

ChatField.propTypes = {
  handleMessage: PropTypes.func.isRequired,
};

ChatField.defaultProps = {
  handleMessage: () => {},
};

export default ChatField;

// region STYLES

const ChatTextField = styled.div`
  height: 64px;
  width: 100%;
  border-top: 1px solid ${colorFieldsBorder};
  position: absolute;
  bottom: 1px;
`;

const ChatForm = styled.div`
  width: 100%;
  position: relative;
  padding: 12px 20px;
`;

const ChatFieldInput = styled.input`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 1.33;
  transition: 0.3s;
  ${paddingLeft}: 15px;
  ${paddingRight}: 65px;
  background-color: ${colorBackgroundBody};
  border: 1px solid ${colorBackgroundBody};
  color: ${colorText};

  &:focus,
  &:hover,
  &:active {
    border-color: ${colorAccent};
    outline: none;
    box-shadow: none;
    background-color: ${colorBackgroundBody};
    color: ${colorText};
  }
`;

const ChatFieldControls = styled.div`
  position: absolute;
  top: 12px;
  height: 36px;
  display: flex;
  ${right}: 35px;
`;

const ChatIconButton = styled.button`
  background: transparent;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  ${marginLeft}: 5px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${colorAdditional};
    transition: 0.3s;
  }

  &:hover {
    svg {
      fill: ${colorAccent};
    }
  }
`;

// endregion
