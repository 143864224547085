import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CreditStatisticsTable from "./components/CreditStatisticsTable";
import PurchaseHistoryTable from "./components/PurchaseHistoryTable";
import PurchaseAnalyticsChart from "./components/PurchaseAnalyticsChart";
import TotalEarningCard from "./components/TotalEarningCard";

const Purchases = () => {
  const [total, setTotal] = useState(0);
  const [growth, setGrowth] = useState(0);
  const [monthValue, setMonthValue] = useState([]);
  const [monthName, setMonthName] = useState([]);

  const months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const getRevenue = () => {
    let token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/purchase_manage/get_info`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setTotal(res.total);
        setGrowth(res.growth);
        setMonthValue(
          res.last_six_month_data.reverse().map((item) => item.amount),
        );
        setMonthName(res.last_six_month_data.map((item) => months[item.month]));
      });
  };

  useEffect(() => {
    getRevenue();
  }, []);

  return (
    <Container className="dashboard" fluid>
      <Row>
        <TotalEarningCard total={total} growth={growth} />
        <PurchaseAnalyticsChart monthValue={monthValue} monthName={monthName} />
      </Row>
      <Row>
        <PurchaseHistoryTable cardTitle="Purchase History" />
        <CreditStatisticsTable cardTitle="Credit Statistics" />
      </Row>
    </Container>
  );
};

export default Purchases;
