import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import toast from "react-hot-toast";
import PersonaData from "./PersonaData";
import useFullLoading from "../../shared/hooks/store/useLoading";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import BabyFaceOutlineIcon from "mdi-react/BabyFaceOutlineIcon";
import { Table } from "@/shared/components/TableElements";
import EditItem from "./components/EditItem";
import FileEditOutlineIcon from "mdi-react/FileEditOutlineIcon";
import interestApi from "../../utils/api/interestApi";
import { Nav, Tab } from "react-bootstrap";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import { BorderedBottomTabs, JustifyTabs } from "@/shared/components/Tabs";
import CreatePersona from "./components/CreatePersona";
import ChatCard from "./components/chat/ChatCard";
import EditTrainItem from "./components/EditTrainItem";
import DeleteModal from "../Members/components/DeleteModal";
import Input from "../../shared/components/Input";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import EditStyle from "./components/EditStyle";
import { BiMessageRounded } from "react-icons/bi";
import EmbeddingModal from "./components/EmbeddingModal";
import { HiMiniWindow, HiOutlineMicrophone } from "react-icons/hi2";
import VoiceModal from "./components/VoiceModal";

const { tableHeaderData } = PersonaData();
const AiTraining = () => {
  const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
  const { setLoading } = useFullLoading();
  const [personaList, setPersonaList] = useState([]);
  const [chatActive, setChatActive] = useState(false);
  const [widget, setWidget] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editTrain, setEditTrain] = useState(false);
  const [interestOption, setInterestOption] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const [progress, setProgress] = useState(0);
  const [editProgress, setEditProgress] = useState(0);
  const [createLoading, setCreateLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageOption, setPageOption] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [keywords, setKeywords] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [embedModal, setEmbedModal] = useState(false);
  const [voiceModal, setVoiceModal] = useState(false);
  const [voiceOptionList, setVoiceOptionList] = useState([]);

  const handleFetchPersonas = useCallback(async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/embed_manage/get_personas`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_keyword: searchValue,
          page_index: offset,
          show_count: pageSize,
        }),
      },
    )
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === "success") {
          setPersonaList(res.data);
          setTotalCount(res.total);
          setEditProgress(0);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, offset, pageSize, searchValue]);

  const handleDeletePersona = useCallback(
    async (id) => {
      const token = localStorage.getItem("token");
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/embed_manage/delete`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        },
      )
        .then(async () => {
          toast.success("success!");
          await handleFetchPersonas();
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, handleFetchPersonas],
  );

  const activeColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <HiMiniWindow
          size={24}
          onClick={(e) => {
            e.stopPropagation();
            setEditData(data);
            setEmbedModal(true);
          }}
        />
        <HiOutlineMicrophone
          size={24}
          onClick={(e) => {
            e.stopPropagation();
            setEditData(data);
            setVoiceModal(true);
          }}
        />
        <BiMessageRounded
          size={24}
          onClick={(e) => {
            e.stopPropagation();
            setEditData(data);
            setWidget(true);
            setEditTrain(false);
            setChatActive(false);
            setEditActive(false);
          }}
          role="button"
        />
        <BabyFaceOutlineIcon
          onClick={(e) => {
            e.stopPropagation();
            setEditData(data);
            setWidget(false);
            setEditTrain(true);
            setChatActive(false);
            setEditActive(false);
          }}
          role="button"
        />
        <FileEditOutlineIcon
          onClick={(e) => {
            e.stopPropagation();
            setEditData(data);
            setChatActive(false);
            setEditActive(true);
            setEditTrain(false);
            setWidget(false);
          }}
          role="button"
        />
        <TrashOutlineIcon
          onClick={(e) => {
            e.stopPropagation();
            setOpenModal(true);
            setDeleteId(data.id);
          }}
          role="button"
        />
      </div>
    ),
    [handleDeletePersona],
  );

  const tableData = useMemo(
    () =>
      personaList?.map((item, index) => ({
        ...item,
        no: index + 1,
        persona_avatar: (
          <Avatar>
            <img
              src={
                item.persona_picture
                  ? `${item.persona_picture}?v=${Date.now()}`
                  : Ava
              }
              alt="Persona Avatar"
            />
          </Avatar>
        ),
        action: activeColumn(item),
        interest: interestOption.filter(
          (interest) => interest.value === item.interest,
        ).length
          ? interestOption.filter(
              (interest) => interest.value === item.interest,
            )[0].label
          : "",
        creator: item.type === "user" ? item.creator_name : "vengo",
        progress: <SuccessLabel>{"Complete"}</SuccessLabel>,
      })),
    [personaList, interestOption],
  );

  const handleFetchInterests = async () => {
    try {
      setLoading(true);
      const res = await interestApi.get();
      setInterestOption([
        ...res.data.data
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((item) => ({
            value: item.id,
            label: item.title,
          })),
      ]);
    } catch (err) {
      toast.error(err);
    }
    setLoading(false);
  };

  const handleSavePersona = async (data) => {
    const token = localStorage.getItem("token");
    setCreateLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("openai_api_key", data.openai_api_key);
    formData.append("bio", data.bio);
    formData.append("instructions", data.instructions);
    formData.append("personality", data.personality);
    formData.append("interest", data.interest);
    formData.append("urls", JSON.stringify(data.url));
    formData.append("is_public", JSON.stringify(true));
    formData.append("type", "admin");
    formData.append("persona_picture", data.files[0]);
    data.knowledge.map((item) =>
      formData.append("knowledge_base_files", item.file),
    );
    data.question.map((item) => formData.append("question_files", item.file));
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/embed_manage/create`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      },
    )
      .then(() => {
        setEditData(null);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSavePersonaUpdate = async (data) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    const formData = new FormData();
    formData.append("id", editData.id);
    formData.append("name", data.name);
    formData.append("bio", data.bio);
    formData.append("personality", data.personality);
    formData.append("instructions", data.instructions);
    formData.append("interest", data.interest);
    formData.append("openai_api_key", data.openai_api_key);
    formData.append("is_public", JSON.stringify(true));
    if (data.files[0] instanceof File) {
      formData.append("persona_picture", data.files[0]);
    }
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/embed_manage/update`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      },
    )
      .then(async (res) => {
        if (res.ok) {
          toast.success("success!");
          await handleFetchPersonas();
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setLoading(false));
  };

  const handleSavePersonaTrain = async (data) => {
    setEditLoading(true);
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("id", editData.id);
    formData.append("urls", JSON.stringify(data.url_retrain));
    formData.append(
      "knowledge_base_files",
      JSON.stringify(data.knowledge_base_files),
    );
    formData.append("question_files", JSON.stringify(data.question_files));
    data.knowledge_retrain.map((item) =>
      formData.append("added_knowledge_base_files", item.file),
    );
    data.question_retrain.map((item) =>
      formData.append("added_question_files", item.file),
    );

    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/embed_manage/retrain`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      },
    )
      .then(() => {
        toast.success("Persona Retrain Success!");
        setEditData(null);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getProfileData = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          }
          throw new Error("Something went wrong");
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetchProgress = () => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/socket/negotiate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
      cache: "no-cache",
    })
      .then((data) => data.json())
      .then((data) => {
        const ws = new WebSocket(data.url, "json.webpubsub.azure.v1");

        ws.onopen = () => console.log("connected");
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.data) {
            setProgress(data.data.progress);
            setEditProgress(data.data.progress);
            if (data.data.progress === 100) {
              handleFetchPersonas();
              setCreateLoading(false);
              setEditLoading(false);
            }
          }
        };
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const fetchVoiceOption = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/voices`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setVoiceOptionList(res.data.voices);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleFetchProgress();
    handleFetchInterests();
    getProfileData(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setUserProfile(result.data);
    });
    fetchVoiceOption();
  }, []);

  useEffect(() => {
    handleFetchPersonas();
  }, [handleFetchPersonas, offset, pageSize]);

  useEffect(() => {
    setPageOption([
      ...Array(Math.floor((totalCount - 1) / pageSize) + 1).keys(),
    ]);
  }, [pageSize, totalCount]);

  useEffect(() => {
    if (keywords.length === 0) {
      setSearchValue("");
    }
    const interval = setInterval(() => {
      setSearchValue(keywords);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [keywords]);

  return (
    <Col md={12} lg={12} xl={12}>
      <BorderedBottomTabs as={JustifyTabs}>
        <Tab.Container defaultActiveKey="1">
          <TabsWrap>
            <Nav className="nav-tabs">
              <NavItem>
                <NavLink eventKey="2">Create</NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="1">Edit</NavLink>
              </NavItem>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="2">
                <CreateItemStyle>
                  <Col md={8}>
                    <CreatePersona
                      onSubmit={handleSavePersona}
                      interestOption={interestOption}
                      progress={progress}
                      createLoading={createLoading}
                    />
                  </Col>
                </CreateItemStyle>
              </Tab.Pane>
              <Tab.Pane eventKey="1">
                <Container className="dashboard" fluid>
                  <Row>
                    <Col md={12} lg={12} xl={6}>
                      <Card>
                        <CardBody>
                          <StyledHeader>
                            <CardTitleWrap>
                              <CardTitle>Personas</CardTitle>
                            </CardTitleWrap>
                            <StyleSearch>
                              <LabelStyle>
                                <label
                                  className="text-light pt-2"
                                  htmlFor="search"
                                >
                                  <CardTitle>Search: </CardTitle>
                                </label>
                              </LabelStyle>
                              <InputStyle>
                                <Input
                                  id="search"
                                  type="text"
                                  placeholder="e.g.Alex Chen"
                                  onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                      setKeywords(e.target.value);
                                    }
                                  }}
                                  onChange={(e) => {
                                    setKeywords(e.target.value);
                                  }}
                                />
                              </InputStyle>
                            </StyleSearch>
                          </StyledHeader>

                          <Table responsive hover>
                            <thead>
                              <tr>
                                {tableHeaderData.map((item) =>
                                  item.Header === "Action" ? (
                                    <ActionHeader key={item.id}>
                                      {item.Header}
                                    </ActionHeader>
                                  ) : (
                                    <th key={item.id}>{item.Header}</th>
                                  ),
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData?.map((item, index) => (
                                <tr
                                  key={index}
                                  onClick={() => {
                                    setChatActive(true);
                                    setEditActive(false);
                                    setEditTrain(false);
                                    setEditData(item);
                                  }}
                                >
                                  <td>{item.no}</td>
                                  <td>{item.persona_avatar}</td>
                                  <td>{item.creator}</td>
                                  <td>{item.name}</td>
                                  <td>{item.progress}</td>
                                  <td className="text-break text-truncate table-item">
                                    {item.bio}
                                  </td>
                                  <td className="text-break text-truncate table-item">
                                    {item.personality}
                                  </td>
                                  <td>{item.interest}</td>
                                  <Action>{item.action}</Action>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <PaginationStyle>
                            <ReactTablePagination
                              page={tableData}
                              gotoPage={(value) => {
                                setOffset(value);
                              }}
                              previousPage={() => {
                                setOffset(offset - 1);
                              }}
                              nextPage={() => {
                                setOffset(offset + 1);
                              }}
                              canPreviousPage={offset > 0}
                              canNextPage={
                                offset !== pageOption[pageOption.length - 1]
                              }
                              pageOptions={pageOption}
                              pageIndex={offset}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              manualPageSize={[10, 20, 30, 40]}
                              dataLength={totalCount}
                            />
                          </PaginationStyle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={12} lg={12} xl={6}>
                      {widget ? <EditStyle personaInfo={editData} /> : <></>}
                      {chatActive ? (
                        <ChatCard
                          personaInfo={editData}
                          currentUserInfo={userProfile}
                          type={0}
                        />
                      ) : (
                        <></>
                      )}
                      {editActive ? (
                        <EditItem
                          onSubmit={handleSavePersonaUpdate}
                          interestOption={interestOption}
                          editData={editData}
                        />
                      ) : (
                        <></>
                      )}
                      {editTrain ? (
                        <EditTrainItem
                          onSubmit={handleSavePersonaTrain}
                          editData={editData}
                          editProgress={editProgress}
                          editLoading={editLoading}
                        />
                      ) : (
                        <></>
                      )}
                      <DeleteModal
                        open={openModal}
                        onClose={() => {
                          setOpenModal(false);
                        }}
                        onDelete={() => {
                          setOpenModal(false);
                          handleDeletePersona(deleteId);
                        }}
                      />
                      <EmbeddingModal
                        title="Add to website"
                        open={embedModal}
                        onClose={() => {
                          setEmbedModal(false);
                        }}
                        editData={editData}
                      />
                      <VoiceModal
                        title="Please choose a voice for this Persona."
                        open={voiceModal}
                        voiceOptionList={voiceOptionList}
                        onClose={() => {
                          setVoiceModal(false);
                        }}
                        editData={editData}
                      />
                    </Col>
                  </Row>
                </Container>
              </Tab.Pane>
            </Tab.Content>
          </TabsWrap>
        </Tab.Container>
      </BorderedBottomTabs>
    </Col>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 20px;
`;

const CreateItemStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  overflow: hidden;
  border-radius: 50%;

  img {
    height: 100%;
    width: 100%;
  }
`;

const Action = styled.td`
  position: sticky;
  right: 0;
  background-color: #232329 !important;
`;

const ActionHeader = styled.th`
  position: sticky;
  right: 0;
  background-color: #232329 !important;
`;

const SuccessLabel = styled.div`
   {
    color: green;
  }
`;

const InputStyle = styled.div`
  width: 200px;
`;

const StyleSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 4px;
`;

const LabelStyle = styled.div`
  width: 55px;
`;

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 14px;
`;

export default AiTraining;
