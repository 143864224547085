import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FileEditOutlineIcon from "mdi-react/FileEditOutlineIcon";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import toast from "react-hot-toast";
import IdentitiesData from "./IdentitiesData";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import identityApi from "../../utils/api/identityApi";
import EditItemModal from "../Interests/components/EditItemModal";
import useFullLoading from "../../shared/hooks/store/useLoading";
import DeleteModal from "../Members/components/DeleteModal";

const Identity = () => {
  const { setLoading } = useFullLoading();
  const { tableHeaderData } = IdentitiesData();
  const [identityList, setIdentityList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState();
  const [disable, setDisable] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetchIdentities = useCallback(async () => {
    try {
      setLoading(true);
      const res = await identityApi.get();
      if (res.status === 200) {
        setIdentityList(
          res.data.data.sort((a, b) => a.title.localeCompare(b.title)),
        );
      }
    } catch (err) {
      toast.error(err);
    }
    setLoading(false);
  }, [setLoading]);

  const handleDeleteIdentity = useCallback(
    async (id) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/identity_manage/delete/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
          .then((res) => res.json())
          .then(async (res) => {
            if (res.status === "success") {
              toast.success("success!");
              await handleFetchIdentities();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.error(err);
      }
    },
    [handleFetchIdentities, setLoading],
  );

  const handleEditIdentity = useCallback((data) => {
    setEditData(data);
    setOpenModal(true);
  }, []);

  const handleSaveIdentity = useCallback(
    async (data) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        editData
          ? fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/identity_manage/update/${editData.id}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              },
            )
              .then((res) => res.json())
              .then(async (res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  await handleFetchIdentities();
                }
              })
              .finally(() => {
                setLoading(false);
              })
          : fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/identity_manage/create`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              },
            )
              .then((res) => res.json())
              .then(async (res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  await handleFetchIdentities();
                }
              })
              .finally(() => {
                setLoading(false);
              });
        setOpenModal(false);
        setEditData(null);
      } catch (err) {
        toast.error(err);
      }
    },
    [editData, handleFetchIdentities, setLoading],
  );

  useEffect(() => {
    handleFetchIdentities();
  }, [handleFetchIdentities]);

  const activeColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <FileEditOutlineIcon
          onClick={() => {
            if (disable.length)
              if (disable[10].update) {
                handleEditIdentity(data);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[10].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[10].delete ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <TrashOutlineIcon
          onClick={() => {
            if (disable.length)
              if (disable[10].delete) {
                setOpenDeleteModal(true);
                setDeleteId(data.id);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[10].delete
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[10].delete ? 1 : 0.5) : "",
          }}
          role="button"
        />
      </div>
    ),
    [handleEditIdentity, disable],
  );

  const tableData = useMemo(
    () =>
      identityList.map((item, index) => ({
        ...item,
        id: index + 1,
        action: activeColumn(item),
      })),
    [activeColumn, identityList],
  );

  useEffect(() => {
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setDisable(result.data.role_permissions);
    });
  }, []);

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Card>
          <CardBody>
            <StyledHeader>
              <CardTitleWrap>
                <CardTitle>Identities</CardTitle>
                <CardSubhead>List of Identities</CardSubhead>
              </CardTitleWrap>
              <StyledButton
                variant="primary"
                onClick={() => {
                  if (disable.length) if (disable[10].write) setOpenModal(true);
                }}
                style={{
                  cursor: disable.length
                    ? disable[10].write
                      ? "pointer"
                      : "not-allowed"
                    : "",
                  opacity: disable.length ? (disable[10].write ? 1 : 0.5) : "",
                }}
              >
                Add New Identities
              </StyledButton>
            </StyledHeader>
            <Col md={12}>
              <Card>
                <CardBody>
                  <ReactTableBase columns={tableHeaderData} data={tableData} />
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </Row>
      <EditItemModal
        title="Identities"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
        onSubmit={handleSaveIdentity}
        editData={editData}
      />
      <DeleteModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteIdentity(deleteId);
        }}
        title={"Are you sure you want to delete this identity?"}
      />
    </Container>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
  margin-bottom: 30px;
`;

export default Identity;
