import React from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { colorBackgroundBody } from "@/utils/palette";
import { paddingLeft } from "@/utils/directions";
import Layout from "../../../Layout/index";
import AppDashboard from "../../../Dashboards/App/index";
import Members from "../../../Members/index";
import AiTraining from "../../../AiTraining/index";
import Interests from "../../../Interests";
import Identities from "../../../Identities";
import Wallpapers from "../../../Wallpapers";
import MessageMetrics from "../../../MessageMetrics";
import AiMetrics from "../../../AiMetrics";
import Purchases from "../../../Purchases";
import UserFeedBack from "../../../UserFeedBack";
import LevelTracker from "../../../LevelTracker";
import AccessControl from "../../../AccessControl";
import ManageAdmins from "../../../ManageAdmins";
import BasePersona from "../../../BasePersona";
import AppBackground from "../../../AppBackground";
import GlobalMessages from "../../../GlobalMessages";
import Questions from "../../../Questions";
import EmbeddingUi from "../../../EmbeddingUi";

export default () => (
  <div>
    <Layout />
    <ContainerWrap>
      <Route exact path="/" component={AppDashboard} />
      <Route exact path="/members" component={Members} />
      <Route exact path="/ai_training" component={AiTraining} />
      <Route exact path="/basepersona" component={BasePersona} />
      <Route exact path="/message_metrics" component={MessageMetrics} />
      <Route exact path="/ai_metrics" component={AiMetrics} />
      <Route exact path="/wallpapers" component={Wallpapers} />
      <Route exact path="/app_background" component={AppBackground} />
      <Route exact path="/interests" component={Interests} />
      <Route exact path="/identities" component={Identities} />
      <Route exact path="/purchases" component={Purchases} />
      <Route exact path="/userfeedback" component={UserFeedBack} />
      <Route exact path="/level_tracker" component={LevelTracker} />
      <Route exact path="/admins" component={ManageAdmins} />
      <Route exact path="/access_controls" component={AccessControl} />
      <Route exact path="/global_messages" component={GlobalMessages} />
      <Route exact path="/questions" component={Questions} />
      <Route exact path="/embedding_ui" component={EmbeddingUi} />
    </ContainerWrap>
  </div>
);

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;

// endregion
