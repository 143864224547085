import config from "@/config";
import { post, deleteRequest } from "./base";

const { VENGOAI_ADMIN_API_BASE_URL } = config;
const url = `${VENGOAI_ADMIN_API_BASE_URL}/admin/wallpaper_manage`;
const urls = `${VENGOAI_ADMIN_API_BASE_URL}/common`;

export default {
  get: () => post(`${urls}/get_wallpapers`),
  create: (data, options) => post(`${url}/create`, data, options),
  update: (id, data, options) =>
    post(`${url}/update_wallpaper/${id}`, data, options),
  delete: (data) => deleteRequest(`${url}/delete/${data}`),
};
