import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserAdvancement from "./componenets/UserAdvancement";
import KnowledgeTree from "./componenets/KnowledgeTree";

const LevelTracker = () => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <Row>
        <UserAdvancement />
      </Row>
      <Row>
        <KnowledgeTree />
      </Row>
    </Container>
  );
};

export default LevelTracker;
