import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import BasicTableData from "./BasicTableData";
import styled from "styled-components";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";

const { tableHeaderResponsiveData, tableRowsData } = BasicTableData();

const UsersMessage = ({ cardSubTitle, cardTitle }) => {
  const { t } = useTranslation("common");
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [messageHistory, setMessageHistory] = useState([]);
  const [pageOption, setPageOption] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleFetchMessages = () => {
    let token = localStorage.getItem("token");
    try {
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/chat_manage/all_history`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            skip: offset,
            limit: pageSize,
          }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          setMessageHistory(res.data.histories);
          setTotalCount(res.data.total);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    handleFetchMessages();
  }, [offset, pageSize]);

  useEffect(() => {
    setPageOption([
      ...Array(Math.floor((totalCount - 1) / pageSize) + 1).keys(),
    ]);
  }, [pageSize, totalCount]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{cardTitle}</CardTitle>
            <CardSubhead>{cardSubTitle}</CardSubhead>
          </CardTitleWrap>
          <Table responsive bordered>
            <thead>
              <tr>
                {tableHeaderResponsiveData.map((item, index) => (
                  <th key={index}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {messageHistory &&
                messageHistory.map((item, index) => (
                  <tr key={index}>
                    <td>{pageSize * offset + index + 1}</td>
                    <td>{item.user_name}</td>
                    <td>{item.message}</td>
                    <td>
                      {new Date(item.created_at)
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " ")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <PaginationStyle>
            <ReactTablePagination
              page={messageHistory}
              gotoPage={(value) => {
                setOffset(value);
              }}
              previousPage={() => {
                setOffset(offset - 1);
              }}
              nextPage={() => {
                setOffset(offset + 1);
              }}
              canPreviousPage={offset > 0 ? true : false}
              canNextPage={
                offset === pageOption[pageOption.length - 1] ? false : true
              }
              pageOptions={pageOption}
              pageIndex={offset}
              pageSize={pageSize}
              setPageSize={setPageSize}
              manualPageSize={[10, 20, 30, 40]}
              dataLength={totalCount}
            />
          </PaginationStyle>
        </CardBody>
      </Card>
    </Col>
  );
};

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 14px;
`;

export default UsersMessage;
