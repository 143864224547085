import React from "react";
import { useTranslation } from "react-i18next";
import { Pie, ResponsiveContainer } from "recharts";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { Col } from "react-bootstrap";
import {
  DashboardWeeklyStatChart,
  DashboardWeeklyStatChartItem,
  DashboardWeeklyStatChartPie,
  DashboardWeeklyStatInfo,
  DashboardWeeklyStatLabel,
  DashboardWeeklyStatPieChart,
  DashboardWeeklyStatWrap,
} from "./BasicStatisticComponents";
import { Box } from "@material-ui/core";

const data01 = [
  { value: 78, fill: "#b8e986" },
  { value: 23, fill: "#eeeeee" },
];

const data02 = [
  { value: 25, fill: "#ff4861" },
  { value: 75, fill: "#eeeeee" },
];

const SystemMonitor = () => {
  const { t } = useTranslation("common");

  return (
    <Col md={12} lg={6} xl={3} xs={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{"System Monitor"}</CardTitle>
          </CardTitleWrap>
          <Box flex={1} display={"flex"} alignItems={"center"}>
            <DashboardWeeklyStatWrap className="">
              <DashboardWeeklyStatChart>
                <DashboardWeeklyStatChartItem>
                  <DashboardWeeklyStatChartPie>
                    <ResponsiveContainer width={110}>
                      <DashboardWeeklyStatPieChart>
                        <Pie
                          data={data01}
                          dataKey="value"
                          cx={50}
                          cy={50}
                          innerRadius={50}
                          outerRadius={55}
                        />
                      </DashboardWeeklyStatPieChart>
                    </ResponsiveContainer>
                    <DashboardWeeklyStatLabel style={{ color: "#b8e986" }}>
                      78%
                    </DashboardWeeklyStatLabel>
                  </DashboardWeeklyStatChartPie>
                  <DashboardWeeklyStatInfo>
                    <p>
                      CPU
                      <br />
                      4.44GHz
                    </p>
                  </DashboardWeeklyStatInfo>
                </DashboardWeeklyStatChartItem>
                <DashboardWeeklyStatChartItem>
                  <DashboardWeeklyStatChartPie>
                    <ResponsiveContainer width={110}>
                      <DashboardWeeklyStatPieChart>
                        <Pie
                          data={data02}
                          dataKey="value"
                          cx={50}
                          cy={50}
                          innerRadius={50}
                          outerRadius={55}
                        />
                      </DashboardWeeklyStatPieChart>
                    </ResponsiveContainer>
                    <DashboardWeeklyStatLabel style={{ color: "#ff4861" }}>
                      25%
                    </DashboardWeeklyStatLabel>
                  </DashboardWeeklyStatChartPie>
                  <DashboardWeeklyStatInfo>
                    <p>
                      RAM
                      <br />
                      4GB/16GB
                    </p>
                  </DashboardWeeklyStatInfo>
                </DashboardWeeklyStatChartItem>
              </DashboardWeeklyStatChart>
            </DashboardWeeklyStatWrap>
          </Box>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SystemMonitor;
