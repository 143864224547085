import React from "react";
import { Container, Row } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { Nav, Tab } from "react-bootstrap";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import TextMessages from "./Components/TextMessages";
import ImageMessages from "./Components/ImageMessages";
import AudioMessages from "./Components/AudioMessages";
import VideoMessages from "./Components/VideoMessages";

const GlobalMessages = () => {
  return (
    <Container className="dashboard" fluid>
      <Card>
        <CardBody>
          <Row>
            <CardTitleWrap>
              <CardTitle>Global Messages</CardTitle>
            </CardTitleWrap>
          </Row>
          <Row>
            <Tab.Container defaultActiveKey="1">
              <TabsWrap>
                <Nav className="nav-tabs">
                  <NavItem>
                    <NavLink eventKey="1">Text</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="2">Photo</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="3">Audio</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="4">Video</NavLink>
                  </NavItem>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <TextMessages />
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <ImageMessages />
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <AudioMessages />
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <VideoMessages />
                  </Tab.Pane>
                </Tab.Content>
              </TabsWrap>
            </Tab.Container>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default GlobalMessages;
