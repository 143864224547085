import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import ProgressBar from "@/shared/components/ProgressBar";
import {
  Card,
  CardTitle,
  CardTitleWrap,
  CardSubhead,
} from "@/shared/components/Card";
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconUp,
} from "../../Dashboards/App/MobileWidgetDashboardElements";
import { DashboardWidgetCard } from "../../Dashboards/BasicDashboardComponents";
import CountUp from "react-countup";
import styled from "styled-components";

const TotalEarningLabel = styled.p`
  font-size: 36px;
  color: #48b5ff;
  text-align: center;
`;

const TotalEarningSubLabel = styled.p`
  font-size: 18px;
`;

const TotalEarningContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const TotalEarningCard = ({ total, growth }) => {
  const { t } = useTranslation("common");

  return (
    <Col md={12} xl={3} lg={12} xs={12}>
      <Card>
        <DashboardWidgetCard>
          <CardTitleWrap>
            <CardTitle>Total Revenue</CardTitle>
            <CardSubhead>This Month</CardSubhead>
          </CardTitleWrap>
          <TotalEarningContent>
            <MobileAppWidgetLine>
              <TotalEarningLabel>
                $<CountUp end={total} start={0} duration={1.5} />
              </TotalEarningLabel>
              <MobileWidgetIconUp />
            </MobileAppWidgetLine>
            <div>
              <TotalEarningSubLabel>
                Growth from last month
              </TotalEarningSubLabel>
              <ProgressBar
                now={100}
                label={`${growth}%"`}
                top
                size="small"
                gradient="blue"
              />
            </div>
          </TotalEarningContent>
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};

export default TotalEarningCard;
