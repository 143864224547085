const BasicTableData = () => {
  const header = [
    { id: 1, title: "#" },
    { id: 2, title: "UserName" },
    { id: 3, title: "Average" },
    { id: 4, title: "Food" },
    { id: 5, title: "Sport" },
    { id: 6, title: "News" },
    { id: 7, title: "Music" },
    { id: 8, title: "Friend" },
  ];

  const headerResponsive = [
    { id: 1, title: "#" },
    { id: 2, title: "Persona" },
    { id: 3, title: "Response Time" },
  ];

  const anaylze = [
    { id: 1, title: "#" },
    { id: 2, title: "Persona" },
    { id: 3, title: "Accuracy" },
    { id: 4, title: "Engagement" },
  ];

  const rows = [
    {
      id: 1,
      chatbot_name: "Food",
      avg_time: "15",
      username: "SunShine891",
      avg: "52",
      sport: "5",
      food: "8",
      News: "9",
      Music: "11",
      friend: "16",
      accuracy: "51%",
      engagement: "14%",
    },
    {
      id: 2,
      chatbot_name: "Sport",
      avg_time: "15",
      username: "SunShine891",
      avg: "52",
      sport: "5",
      food: "8",
      News: "9",
      Music: "11",
      friend: "16",
      accuracy: "29%",
      engagement: "14%",
    },
    {
      id: 3,
      chatbot_name: "News",
      avg_time: "15",
      username: "SunShine891",
      avg: "52",
      sport: "5",
      food: "8",
      News: "9",
      Music: "11",
      friend: "16",
      accuracy: "51%",
      engagement: "11%",
    },
    {
      id: 4,
      chatbot_name: "Music",
      avg_time: "15",
      username: "SunShine891",
      avg: "52",
      sport: "5",
      food: "8",
      News: "9",
      Music: "11",
      friend: "16",
      accuracy: "45%",
      engagement: "36%",
    },
    {
      id: 5,
      chatbot_name: "Friend",
      avg_time: "15",
      username: "SunShine891",
      avg: "52",
      sport: "5",
      food: "8",
      News: "9",
      Music: "11",
      friend: "16",
      accuracy: "26%",
      engagement: "16%",
    },
    {
      id: 6,
      chatbot_name: "Food",
      avg_time: "15",
      username: "SunShine891",
      avg: "52",
      sport: "5",
      food: "8",
      News: "9",
      Music: "11",
      friend: "16",
      accuracy: "58%",
      engagement: "20%",
    },
    {
      id: 7,
      chatbot_name: "Food",
      avg_time: "15",
      username: "SunShine891",
      avg: "52",
      sport: "5",
      food: "8",
      News: "9",
      Music: "11",
      friend: "16",
      accuracy: "28%",
      engagement: "34%",
    },
  ];

  const basicTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
    tableHeaderAnaylzeData: anaylze,
    tableRowsData: rows,
  };
  return basicTableData;
};

export default BasicTableData;
