const BasicTableData = () => {
  const headerText = [
    { accessor: "id", Header: "#" },
    { accessor: "title", Header: "Title" },
    { accessor: "content", Header: "Content" },
    { accessor: "action", Header: "Action" },
  ];

  const headerImage = [
    { accessor: "id", Header: "#" },
    { accessor: "image", Header: "Image" },
    { accessor: "title", Header: "Title" },
    { accessor: "content", Header: "Content" },
    { accessor: "action", Header: "Action" },
  ];

  const basicTableData = {
    tableTextHeaderData: headerText,
    tableImageHeaderData: headerImage,
  };
  return basicTableData;
};

export default BasicTableData;
