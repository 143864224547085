import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import toast from "react-hot-toast";
import MemberData from "./MemberData";
import DeleteModal from "./components/DeleteModal";
import { Table } from "@/shared/components/TableElements";
import useFullLoading from "../../shared/hooks/store/useLoading";
import Badge from "@/shared/components/Badge";
import Input from "../../shared/components/Input";
import ReactTablePagination from "../../shared/components/table/components/ReactTablePagination";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import AccountCancelIcon from "mdi-react/AccountCancelIcon";
import AccountCheckIcon from "mdi-react/AccountCheckIcon";
import { SelectField } from "@/shared/components/form/Select";
import interestApi from "../../utils/api/interestApi";
import identityApi from "../../utils/api/identityApi";
import InfiniteScroll from "react-infinite-scroll-component";

const { tableHeaderData } = MemberData();
const Interests = () => {
  const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
  const { setLoading } = useFullLoading();
  const [memberList, setMemberList] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [offset, setOffset] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  // const [pageOption, setPageOption] = useState([]);
  const [interestOption, setInterestOption] = useState([]);
  const [interestFilter, setInterestFilter] = useState([]);
  const [identityOption, setIdentityOption] = useState([]);
  const [identityFilter, setIdentityFilter] = useState([]);
  const [disable, setDisable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetchMembers = useCallback(
    async (value, newOffset) => {
      let currentOffset = newOffset !== undefined ? newOffset : offset;
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/get_users`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              search_keyword: value ?? "",
              page_index: currentOffset,
              show_count: 20,
              filters: {
                status: "",
                identities: identityFilter ?? [],
                interests: interestFilter ?? [],
              },
            }),
          },
        )
          .then((res) => res.json())
          .then(async (res) => {
            if (res.status === "success") {
              if (memberList.length + res.data.length >= res.total) {
                setHasMore(false);
              }
              setMemberList((prevMembers) => {
                const newMemberList = [...prevMembers, ...res.data];
                return newMemberList.filter(
                  (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
                );
              });
              setTotalCount(res.total);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.success(err);
      }
    },
    [setLoading, offset, hasMore],
  );
  const handleMembers = async () => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/get_users`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_keyword: "",
            page_index: 0,
            show_count: 20,
            filters: {
              status: "",
              identities: [],
              interests: [],
            },
          }),
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            setHasMore(true);
            setMemberList(res.data);
            setTotalCount(res.total);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.success(err);
    }
  };

  const handleFetchInterests = async () => {
    try {
      setLoading(true);
      const res = await interestApi.get();
      setInterestOption([
        ...res.data.data.map((item) => ({
          value: item.id,
          label: item.title,
        })),
      ]);
    } catch (err) {
      toast.success(err);
    }
    setLoading(false);
  };

  const handleFetchIdentities = useCallback(async () => {
    try {
      setLoading(true);
      const res = await identityApi.get();
      setIdentityOption([
        ...res.data.data.map((item) => ({
          value: item.id,
          label: item.title,
        })),
      ]);
    } catch (err) {
      toast.error(err);
    }
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    handleFetchInterests();
    handleFetchIdentities();
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setDisable(result.data.role_permissions);
    });
  }, []);

  const loadMore = () => {
    setOffset((prevOffset) => prevOffset + 1);
    handleFetchMembers(searchValue, offset);
  };

  useEffect(() => {
    handleFetchMembers(searchValue, offset);
  }, [offset, searchValue, interestFilter, identityFilter]);

  useEffect(() => {
    setOffset(0);
    setMemberList([]);
    setHasMore(true);
  }, [searchValue, interestFilter, identityFilter]);

  const statusColumn = (data) => {
    let temp = data.status ?? 0;
    if (temp === 0) {
      return <Badge bg={"success"}>Pending</Badge>;
    } else if (temp === 1) {
      return <Badge bg={"primary"}>Approved</Badge>;
    } else {
      return <Badge bg={"secondary"}>Blocked</Badge>;
    }
  };

  const selectColumn = (data) => {
    return (
      <StyledInput
        type="checkbox"
        checked={items.filter((it) => it === data.id).length > 0}
        onChange={() => {
          if (items.filter((it) => it === data.id).length > 0) {
            setItems((previous) => previous.filter((it) => it !== data.id));
          } else {
            setItems([...items, data.id]);
          }
        }}
      />
    );
  };

  const handleDeleteMember = async (ids) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/delete_users`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ids: [...new Set(ids)] }),
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            setItems([]);
            toast.success("success!");
            await handleMembers();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const handleBlockMember = async (ids) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/block_users`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ids: [...new Set(ids)] }),
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            setItems([]);
            toast.success("success!");
            await handleMembers();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const handleApproveMember = async (ids) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/approve_users`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ids: [...new Set(ids)] }),
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            setItems([]);
            toast.success("success!");
            await handleMembers();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const actionColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <AccountCheckIcon
          onClick={() => {
            if (disable.length)
              if (disable[3].update) {
                if (items.length === 0) {
                  toast.error("Select the members");
                } else {
                  handleApproveMember(items);
                }
              }
          }}
          style={{
            cursor: disable.length
              ? disable[3].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[3].update ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <AccountCancelIcon
          onClick={() => {
            if (disable.length)
              if (disable[3].update) {
                if (items.length === 0) {
                  toast.error("Select the members");
                } else {
                  handleBlockMember(items);
                }
              }
          }}
          style={{
            cursor: disable.length
              ? disable[3].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[3].update ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <TrashOutlineIcon
          onClick={() => {
            if (disable.length)
              if (disable[3].delete) {
                if (items.length === 0) {
                  toast.error("Select the members");
                } else {
                  setOpenModal(true);
                  setDeleteId(items);
                }
              }
          }}
          style={{
            cursor: disable.length
              ? disable[3].delete
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[3].delete ? 1 : 0.5) : "",
          }}
          role="button"
        />
      </div>
    ),
    [handleBlockMember, handleApproveMember, disable, items],
  );

  const tableData = useMemo(
    () =>
      memberList &&
      memberList.map((item, index) => ({
        ...item,
        selectId: selectColumn(item),
        id: index + 1,
        birthday: item.birthday ? item.birthday.split("T")[0] : "",
        identity: item.identities.join(", "),
        interests: item.interests.join(", "),
        status: statusColumn(item),
        action: actionColumn(item),
        image: item.profile_picture ? (
          <Avatar>
            <img src={item.profile_picture} style={{ width: "35px" }} alt="" />
          </Avatar>
        ) : (
          <Avatar>
            <img src={Ava} alt="avatar" style={{ width: "35px" }} />
          </Avatar>
        ),
      })),
    [memberList, statusColumn, selectColumn],
  );

  useEffect(() => {
    if (keywords.length === 0) {
      setSearchValue("");
    }
    const interval = setInterval(() => {
      setSearchValue(keywords);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [keywords]);

  useEffect(() => {
    if (selectAll) {
      setItems([...items, ...memberList.map((it) => it.id)]);
    } else {
      setItems([]);
    }
  }, [selectAll]);

  return (
    <Container className="dashboard" fluid>
      <Card>
        <CardBody>
          <Row>
            <CardTitleWrap>
              <CardTitle>Members</CardTitle>
              <CardSubhead>List of Members</CardSubhead>
            </CardTitleWrap>
            <StyledHeader>
              <FilterStyle>
                <StyleSearch>
                  <LabelStyle>
                    <label className="text-light pt-2" htmlFor="search">
                      <CardTitle>Search: </CardTitle>
                    </label>
                  </LabelStyle>
                  <InputStyle>
                    <Input
                      id="search"
                      type="text"
                      placeholder="e.g.Alex Chen"
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          setKeywords(e.target.value);
                        }
                      }}
                      onChange={(e) => {
                        setKeywords(e.target.value);
                      }}
                    />
                  </InputStyle>
                </StyleSearch>
                <StyleSearch>
                  <LabelStyle>
                    <label className="text-light pt-2" htmlFor="search">
                      <CardTitle>Interest: </CardTitle>
                    </label>
                  </LabelStyle>
                  <InputStyle>
                    <SelectField
                      options={interestOption}
                      isMulti
                      onChange={(val) => {
                        setInterestFilter(val.map((item) => item.value));
                      }}
                      closeOnSelect={false}
                      removeSelected={false}
                    />
                  </InputStyle>
                </StyleSearch>
                <StyleSearch>
                  <LabelStyle>
                    <label className="text-light pt-2" htmlFor="search">
                      <CardTitle>Identity : </CardTitle>
                    </label>
                  </LabelStyle>
                  <InputStyle>
                    <SelectField
                      options={identityOption}
                      isMulti
                      onChange={(val) => {
                        setIdentityFilter(val.map((item) => item.value));
                      }}
                      closeOnSelect={false}
                      removeSelected={false}
                    />
                  </InputStyle>
                </StyleSearch>
              </FilterStyle>
              <StyledButton variant="primary" onClick={() => {}}>
                Export CSV
              </StyledButton>
            </StyledHeader>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <InfiniteScroll
                    dataLength={memberList.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                  >
                    <Table responsive hover>
                      <thead>
                        <tr>
                          {tableHeaderData.map((item) =>
                            item.Header === "Action" ? (
                              <ActionHeader key={item.id}>
                                {item.Header}
                              </ActionHeader>
                            ) : item.accessor === "select" ? (
                              <th key={item.id}>
                                <StyledInput
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={() => {
                                    setSelectAll(!selectAll);
                                  }}
                                />
                              </th>
                            ) : (
                              <th key={item.id}>{item.Header}</th>
                            ),
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData &&
                          tableData.map((item, index) => (
                            <tr key={index}>
                              <td style={{ paddingTop: "16px" }}>
                                {item.selectId}
                              </td>
                              <td>{item.id}</td>
                              <td>{item.image}</td>
                              <td>{item.user_name}</td>
                              <td>{item.email}</td>
                              <td>{item.birthday}</td>
                              <td className="text-break text-truncate table-item">
                                {item.identity}
                              </td>
                              <td className="text-break text-truncate table-item">
                                {item.interests}
                              </td>
                              <td>{item.status}</td>
                              <Action>{item.action}</Action>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                  {/* <PaginationStyle>
                    <ReactTablePagination
                      page={tableData}
                      gotoPage={(value) => {
                        setOffset(value);
                      }}
                      previousPage={() => {
                        setOffset(offset - 1);
                      }}
                      nextPage={() => {
                        setOffset(offset + 1);
                      }}
                      canPreviousPage={offset > 0 ? true : false}
                      canNextPage={
                        offset === pageOption[pageOption.length - 1]
                          ? false
                          : true
                      }
                      pageOptions={pageOption}
                      pageIndex={offset}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      manualPageSize={[10, 20, 30, 40]}
                      dataLength={totalCount}
                    />
                  </PaginationStyle> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
        <DeleteModal
          open={openModal}
          title={"Are you sure you want to delete this member?"}
          onClose={() => {
            setOpenModal(false);
          }}
          onDelete={() => {
            setOpenModal(false);
            handleDeleteMember(items);
          }}
        />
      </Card>
    </Container>
  );
};

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 490px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
`;

const StyleSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const FilterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 1240px) {
    flex-direction: column;
  }
`;

const InputStyle = styled.div`
  width: 200px;
`;

const LabelStyle = styled.div`
  width: 55px;
`;

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 14px;
`;

const StyledInput = styled.input`
  height: 18px;
  width: 18px;
`;

const Avatar = styled.div`
  width: 35px;
  height: 35px;
  min-width: 35px;
  overflow: hidden;
  border-radius: 50%;
  display: none;

  img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 425px) {
    display: block;
  }
`;

const Action = styled.td`
  position: sticky;
  right: 0;
  background-color: #232329 !important;
`;

const ActionHeader = styled.th`
  position: sticky;
  right: 0;
  background-color: #232329 !important;
`;

export default Interests;
