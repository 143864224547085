import React, { useEffect, useState } from "react";
import { Button } from "@/shared/components/Button";
import { Col, Row, Container } from "react-bootstrap";
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { FormField } from "@/containers/Form/ReactHookForm/FormHookValidation/components/FormField";
import renderDropZoneField from "@/shared/components/form/dropzones/DropZone";
import { useForm } from "react-hook-form";
import { Card, CardBody } from "@/shared/components/Card";
import styled from "styled-components";

const EditItem = ({ onSubmit, editData, interestOption }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [view, setView] = useState(false);
  const [tag, setTag] = useState("");

  useEffect(() => {
    if (editData) {
      setValue("name", editData.name);
      setValue("bio", editData.bio);
      setTag(editData.interest);
      setValue("personality", editData.personality);
      setValue("url", editData.url);
      setValue("files", [{ preview: editData.persona_picture }]);
    }
  }, [editData, setValue]);

  const onSubmitForm = (data) => {
    data.interest = tag;
    onSubmit(data);
  };

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <FormContainer onSubmit={handleSubmit(onSubmitForm)}>
                <FormGroup>
                  <FormField
                    name="files"
                    component={renderDropZoneField}
                    control={control}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Name</FormGroupLabel>
                  <FormGroupField>
                    <FormField
                      name="name"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{ required: "This is required field" }}
                      defaultValue=""
                      placeholder="e.g.Alex Chen"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Bio</FormGroupLabel>
                  <FormGroupField>
                    <FormField
                      name="bio"
                      control={control}
                      component="textarea"
                      errors={errors}
                      rules={{
                        required: "This is required field",
                        minLength: {
                          value: 50,
                          message: "Minimum character length is 50 characters",
                        },
                      }}
                      defaultValue=""
                      placeholder="e.g. “Alex, with her Taiwanese roots, brings a vibrant flair to the gaming world. Specializing in a range of genres, from adrenaline-fueled FPS games to richly detailed RPGs, she's adept at elevating players' skills, whether they're casual enthusiasts or aspiring eSports professionals. Alex combines strategic gameplay with character development insights, offering a holistic approach to gaming. Join her for an immersive journey into the gaming universe, where every session promises to be a leap towards mastery.”"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Personality Pre-Prompt</FormGroupLabel>
                  <FormGroupField>
                    <FormField
                      name="personality"
                      control={control}
                      component="textarea"
                      errors={errors}
                      rules={{
                        required: "This is required field",
                        minLength: {
                          value: 50,
                          message: "Minimum character length is 50 characters",
                        },
                      }}
                      defaultValue=""
                      placeholder="Create a character profile for the AI persona, detailing their expertise, personality, and user interaction. Focus on areas like type of knowledge, enthusiasm, and vocabulary style. Your aim is to clearly represent the persona's traits and how they engage with users. For example, for a Gaming Expert and Coach, highlight their extensive gaming knowledge, list of skills, passion for gaming, and personalized coaching approach."
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <TagLabelStyle>
                    <FormGroupLabel>Choose a Category</FormGroupLabel>
                    {view ? (
                      <div
                        className="text-secondary cursor-point"
                        onClick={() => setView(false)}
                      >
                        View Less
                      </div>
                    ) : (
                      <div
                        className="text-secondary cursor-point"
                        onClick={() => setView(true)}
                      >
                        View All
                      </div>
                    )}
                  </TagLabelStyle>
                  <FormGroupField>
                    <TagGroup>
                      {interestOption &&
                        (view
                          ? interestOption
                          : interestOption.slice(0, 10)
                        ).map((interest) => {
                          if (interest.value === tag) {
                            return (
                              <TagSelect onClick={() => setTag(interest.value)}>
                                {interest.label}
                              </TagSelect>
                            );
                          } else {
                            return (
                              <Tag onClick={() => setTag(interest.value)}>
                                {interest.label}
                              </Tag>
                            );
                          }
                        })}
                    </TagGroup>
                  </FormGroupField>
                </FormGroup>
                <div>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </FormContainer>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditItem;

const TagLabelStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TagGroup = styled.div`
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Tag = styled.div`
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`;

const TagSelect = styled.div`
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  cursor: pointer;
  background-color: white;
`;
