const BasicTableData = () => {
  const header = [
    { id: "1", Header: "Name" },
    { id: "2", Header: "Progress" },
    { id: "3", Header: "Personality" },
    { id: "4", Header: "Action" },
  ];

  const basicTableData = {
    tableHeaderData: header,
  };
  return basicTableData;
};

export default BasicTableData;
