import React, { useMemo } from "react";
import { Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import styled from "styled-components";

const ChartBox = styled.div`
  .chartjs-render-monitor {
    max-height: 300px;
    min-height: 300px;
  }
`;

const PurchaseAnalyticsChart = ({ monthValue, monthName }) => {
  const data = useMemo(
    () => ({
      labels: monthName,
      datasets: [
        {
          label: "Financial Statistics For Last Six Months",
          backgroundColor: "#48b5ff",
          borderColor: "#48b5ff",
          borderWidth: 1,
          hoverBackgroundColor: "#48b5ff",
          hoverBorderColor: "#48b5ff",
          data: monthValue,
        },
      ],
    }),
    [monthValue, monthName],
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgb(204, 204, 204)",
            borderDash: [3, 3],
          },
          ticks: {
            fontColor: "rgb(204, 204, 204)",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgb(204, 204, 204)",
            borderDash: [3, 3],
          },
          ticks: {
            fontColor: "rgb(204, 204, 204)",
          },
        },
      ],
    },
  };

  return (
    <Col md={12} lg={12} xl={9}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>Monthly Revenue</CardTitle>
          </CardTitleWrap>
          <ChartBox>
            <Bar data={data} options={options} />
          </ChartBox>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PurchaseAnalyticsChart;
