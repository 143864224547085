const BasicTableData = () => {
  const header = [
    { id: 1, title: "#" },
    { id: 2, title: "UserName" },
    { id: 3, title: "Total" },
    { id: 4, title: "Hour" },
    { id: 5, title: "Day" },
    { id: 6, title: "Week" },
    { id: 7, title: "Month" },
  ];

  const headerResponsive = [
    { id: 1, title: "#" },
    { id: 2, title: "Creator" },
    { id: 3, title: "Message" },
    { id: 4, title: "Created Time" },
  ];

  const rows = [
    {
      id: 1,
      username: "SunShine891",
      msg_total: "155000",
      msg_hour: "15",
      msg_day: "212",
      msg_week: "875",
      msg_month: "3258",
      content: "How are you today?",
      creator: "blackspider",
      created_at: "2023.12.20",
    },
    {
      id: 2,
      username: "SunShine891",
      msg_total: "155000",
      msg_hour: "15",
      msg_day: "212",
      msg_week: "875",
      msg_month: "3258",
      content: "How are you today?",
      creator: "blackspider",
      created_at: "2023.12.20",
    },
    {
      id: 3,
      username: "SunShine891",
      msg_total: "155000",
      msg_hour: "15",
      msg_day: "212",
      msg_week: "875",
      msg_month: "3258",
      content: "How are you today?",
      creator: "blackspider",
      created_at: "2023.12.20",
    },
    {
      id: 4,
      username: "SunShine891",
      msg_total: "155000",
      msg_hour: "15",
      msg_day: "212",
      msg_week: "875",
      msg_month: "3258",
      content: "How are you today?",
      creator: "blackspider",
      created_at: "2023.12.20",
    },
    {
      id: 5,
      username: "SunShine891",
      msg_total: "155000",
      msg_hour: "15",
      msg_day: "212",
      msg_week: "875",
      msg_month: "3258",
      content: "How are you today?",
      creator: "blackspider",
      created_at: "2023.12.20",
    },
    {
      id: 6,
      username: "SunShine891",
      msg_total: "155000",
      msg_hour: "15",
      msg_day: "212",
      msg_week: "875",
      msg_month: "3258",
      content: "How are you today?",
      creator: "blackspider",
      created_at: "2023.12.20",
    },
    {
      id: 7,
      username: "SunShine891",
      msg_total: "155000",
      msg_hour: "15",
      msg_day: "212",
      msg_week: "875",
      msg_month: "3258",
      content: "How are you today?",
      creator: "blackspider",
      created_at: "2023.12.20",
    },
  ];

  const basicTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
    tableRowsData: rows,
  };
  return basicTableData;
};

export default BasicTableData;
