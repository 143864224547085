import config from "@/config";
import { post, deleteRequest } from "./base";

const { VENGOAI_ADMIN_API_BASE_URL } = config;
const url = `${VENGOAI_ADMIN_API_BASE_URL}/admin/notification_manage`;

export default {
  get: (data) => post(`${url}/get_notifications`, data),
  delete: (id) => deleteRequest(`${url}/delete/${id}`),
  create: (data) => post(`${url}/post`, data),
};
