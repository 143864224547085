import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import TotalUsersCard from "./components/TotalUsersCard";
import ActiveDailyUsersCard from "./components/ActiveDailyUsersCard";
import ActiveWeeklyUsersCard from "./components/ActiveWeeklyUsersCard";
import ActiveMonthlyUsersCard from "./components/ActiveMonthlyUsersCard";
import AgeUsersDoughnut from "./components/AgeUsersDoughnut";
import AiFriendsTable from "./components/AiFriendsTable";
import TrendStatistics from "./components/TrendStatistics";

const AppDashboard = () => {
  const [totalUser, setTotalUser] = useState(0);
  const [dailyUser, setDailyUser] = useState(0);
  const [weeklylUser, setWeeklyUser] = useState(0);
  const [monthlyUser, setMonthlyUser] = useState(0);
  const [memberAge, setMemberAge] = useState([]);
  const [popularPersonas, setPopularPersonas] = useState([]);
  const { t } = useTranslation("common");

  const getUsers = () => {
    let token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/user_count`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setTotalUser(res.data.total);
        setDailyUser(res.data.daily);
        setWeeklyUser(res.data.weekly);
        setMonthlyUser(res.data.monthly);
      });
  };

  const getMemberAge = () => {
    let token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/age_analysis`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setMemberAge(res.data);
      });
  };

  const getPopularPersonas = () => {
    let token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/popular_personas`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setPopularPersonas(res.data);
      });
  };

  useEffect(() => {
    getUsers();
    getMemberAge();
    getPopularPersonas();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <h3 className="page-title">{t("app_dashboard.analytics_title")}</h3>
      </Row>
      <Row>
        <TotalUsersCard totalUser={totalUser} />
        <ActiveDailyUsersCard dailyUser={dailyUser} />
        <ActiveWeeklyUsersCard weeklylUser={weeklylUser} />
        <ActiveMonthlyUsersCard monthlyUser={monthlyUser} />
      </Row>
      <Row>
        <AgeUsersDoughnut memberAge={memberAge} />
        <AiFriendsTable data={popularPersonas} />
      </Row>
      <Row>
        <TrendStatistics />
      </Row>
    </Container>
  );
};

export default AppDashboard;
