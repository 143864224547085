import styled from "styled-components";
import ChatHeader from "./components/ChatHeader";
import ChatContent from "./components/ChatContent";
import ChatInput from "./components/ChatInput";
import { useCallback, useEffect, useMemo, useState } from "react";

export default function ChatPage({ match }) {
  const personaId = useMemo(() => match.params.id, [match]);

  const [personaInfo, setPersonaInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: "",
      type: "LOAD",
    },
  ]);

  const handleFetchPersonaInfo = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/embed_persona/persona/${personaId}`,
        { method: "GET" },
      );

      const personaInfo = await response.json();

      setPersonaInfo(personaInfo.data);
      setMessages([
        {
          message: personaInfo.data.first_message,
          type: "AI",
        },
      ]);
    } catch (err) {
      console.log(err);
    }
  }, [personaId]);

  function base64ToBlob(base64, mimeType) {
    // Remove data URL prefix if it exists
    base64 = base64.includes(",") ? base64.split(",")[1] : base64;

    const bytes = atob(base64);
    const array = new Uint8Array(bytes.length);

    for (let i = 0; i < bytes.length; i++) {
      array[i] = bytes.charCodeAt(i);
    }

    return new Blob([array], { type: mimeType });
  }

  const getAudioBlob = async (base64String) => {
    const audioBlob = base64ToBlob(base64String, "audio/wav");
    return audioBlob;
  };

  const handleSendQuestion = useCallback(
    async (text, images, audio) => {
      if ((text.trim().length > 0 || images.length > 0 || audio) && !loading) {
        const oldMessages = [
          ...messages,
          {
            message: text,
            images: images,
            audio: audio,
            type: "HUMAN",
          },
        ];
        setMessages((prev) => [
          ...prev,
          {
            message: text,
            images: images,
            audio: audio,
            type: "HUMAN",
          },
          {
            type: "LOAD",
            message: "",
          },
        ]);

        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("question", text);
          images.map((item) => formData.append("image_files", item));
          if (audio) {
            formData.append("audio_file", audio);
          }

          const response = await fetch(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/embed_persona/chat/${personaId}`,
            {
              method: "POST",
              body: formData,
            },
          );

          const result = await response.json();

          let audio_blob = undefined;
          if (result.data?.ai_voice) {
            audio_blob = await getAudioBlob(result.data.ai_voice);
          }
          setMessages([
            ...oldMessages,
            {
              message: result.data.answer,
              audio: audio_blob,
              type: "AI",
            },
          ]);
        } catch (err) {
          setMessages([...oldMessages]);
          console.log(err);
        }
        setLoading(false);
      }
    },
    [messages, loading],
  );

  useEffect(() => {
    handleFetchPersonaInfo();
  }, [handleFetchPersonaInfo]);

  return (
    <EmbedChat>
      <ChatHeader persona={personaInfo} />
      <ChatContent messages={messages} style={personaInfo} />
      <ChatInput onSendQuestion={handleSendQuestion} isLoading={loading} />
    </EmbedChat>
  );
}

const EmbedChat = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
