const BasicTableData = () => {
  const header = [
    { accessor: "id", Header: "#" },
    { accessor: "image", Header: "Image" },
    { accessor: "action", Header: "Action" },
  ];

  const basicTableData = {
    tableHeaderData: header,
  };
  return basicTableData;
};

export default BasicTableData;
