import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import toast from "react-hot-toast";
import PersonaData from "./PersonaData";
import useFullLoading from "../../shared/hooks/store/useLoading";
import BabyFaceOutlineIcon from "mdi-react/BabyFaceOutlineIcon";
import { Table } from "@/shared/components/TableElements";
import EditItem from "./components/EditItem";
import FileEditOutlineIcon from "mdi-react/FileEditOutlineIcon";
import EditTrainItem from "./components/EditTrainItem";
import ChatCard from "../Chat/components/ChatCard";

const { tableHeaderData } = PersonaData();
const AiTraining = () => {
  const { setLoading } = useFullLoading();
  const [basePersona, setBasePersona] = useState();
  const [editActive, setEditActive] = useState(false);
  const [editTrain, setEditTrain] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [progress, setProgress] = useState(0);
  const [editLoading, setEditLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [userProfile, setUserProfile] = useState();
  const [disable, setDisable] = useState([]);

  const handleFetchPersonas = useCallback(async () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/get_base_persona`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === "success") {
          setBasePersona(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const activeColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <BabyFaceOutlineIcon
          onClick={(e) => {
            // if (disable.length)
            //   if (disable[2].update) {
            e.stopPropagation();
            setEditTrain(true);
            setEditActive(false);
            setChatActive(false);
            // }
          }}
          // style={{
          //   cursor: disable.length
          //     ? disable[2].update
          //       ? "pointer"
          //       : "not-allowed"
          //     : "",
          //   opacity: disable.length ? (disable[2].update ? 1 : 0.5) : "",
          // }}
          role="button"
        />
        <FileEditOutlineIcon
          onClick={(e) => {
            // if (disable.length)
            // if (disable[2].update) {
            e.stopPropagation();
            setEditActive(true);
            setEditTrain(false);
            setChatActive(false);
            // }
          }}
          // style={{
          //   cursor: disable.length
          //     ? disable[2].update
          //       ? "pointer"
          //       : "not-allowed"
          //     : "",
          //   opacity: disable.length ? (disable[2].update ? 1 : 0.5) : "",
          // }}
          role="button"
        />
      </div>
    ),
    [],
  );

  const tableData = useMemo(
    () =>
      basePersona && {
        ...basePersona,
        action: activeColumn(basePersona),
        progress: <SuccessLabel>{"Complete"}</SuccessLabel>,
        id: basePersona._id,
      },
    [basePersona],
  );

  const handleSavePersona = async (data) => {
    let token = localStorage.getItem("token");
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/update_base_persona`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
    )
      .then(async (res) => {
        if (res.ok) {
          toast.success("success!");
          await handleFetchPersonas();
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.err(err);
      });
  };

  const handleSavePersonaTrain = async (data) => {
    setEditLoading(true);
    let token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("urls", JSON.stringify(data.url_retrain));
    formData.append(
      "knowledge_base_files",
      JSON.stringify(data.knowledge_base_files),
    );
    formData.append("question_files", JSON.stringify(data.question_files));
    data.knowledge_retrain.map((item) =>
      formData.append("added_knowledge_base_files", item.file),
    );
    data.question_retrain.map((item) =>
      formData.append("added_question_files", item.file),
    );

    try {
      await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/train_base_persona`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        },
      );
    } catch (err) {
      toast.error(err);
    }
  };

  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetchProgress = () => {
    let token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/socket/negotiate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
      cache: "no-cache",
    })
      .then((data) => data.json())
      .then((data) => {
        let ws = new WebSocket(data.url, "json.webpubsub.azure.v1");

        ws.onopen = () => console.log("connected");
        ws.onmessage = (event) => {
          let data = JSON.parse(event.data);
          if (data.data) {
            setProgress(data.data.progress);
            if (data.data.progress === 100) {
              setEditLoading(false);
              handleFetchPersonas();
            }
          }
        };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleFetchProgress();
    handleFetchPersonas();
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setUserProfile(result.data);
      setDisable(result.data.role_permissions);
    });
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Container className="dashboard" fluid>
        <Row>
          <Col md={12} lg={12} xl={6}>
            <Card>
              <CardBody>
                <StyledHeader>
                  <CardTitleWrap>
                    <CardTitle>Base Persona</CardTitle>
                  </CardTitleWrap>
                </StyledHeader>
                <Table responsive hover>
                  <thead>
                    <tr>
                      {tableHeaderData.map((item) =>
                        item.Header === "Action" ? (
                          <ActionHeader key={item.id}>
                            {item.Header}
                          </ActionHeader>
                        ) : (
                          <th key={item.id}>{item.Header}</th>
                        ),
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData && (
                      <tr
                        onClick={() => {
                          setChatActive(true);
                          setEditActive(false);
                          setEditTrain(false);
                          setEditData(tableData);
                        }}
                      >
                        <td>{tableData.name}</td>
                        <td>{tableData.progress}</td>
                        <td className="text-break text-truncate table-item">
                          {tableData.personality}
                        </td>
                        <Action>{tableData.action}</Action>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={12} xl={6}>
            {chatActive ? (
              <ChatCard
                personaInfo={editData}
                currentUserInfo={userProfile}
                type={1}
              />
            ) : (
              <></>
            )}
            {editActive ? (
              <EditItem onSubmit={handleSavePersona} editData={basePersona} />
            ) : (
              <></>
            )}
            {editTrain ? (
              <EditTrainItem
                onSubmit={handleSavePersonaTrain}
                editData={basePersona}
                editProgress={progress}
                editLoading={editLoading}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
`;

const CreateItemStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 70px;
  max-height: 70px;
  border-radius: 55px;
`;

const Action = styled.td`
  position: sticky;
  right: 0;
  background-color: #232329 !important;
`;

const ActionHeader = styled.th`
  position: sticky;
  right: 0;
  background-color: #232329 !important;
`;

const SuccessLabel = styled.div`
   {
    color: green;
  }
`;

const FailuareLabel = styled.div`
   {
    color: darkmagenta;
  }
`;

export default AiTraining;
