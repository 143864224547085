const BasicTableData = () => {
  const header = [
    { id: "1", Header: "#" },
    { id: "2", Header: "Title" },
    { id: "3", Header: "Action" },
  ];

  const headerItem = [
    { id: "1", Header: "Module" },
    { id: "2", Header: "Read" },
    { id: "3", Header: "Write" },
    { id: "4", Header: "Update" },
    { id: "5", Header: "Delete" },
  ];

  const basicTableData = {
    tableHeaderData: header,
    tableHeaderItem: headerItem,
  };
  return basicTableData;
};

export default BasicTableData;
