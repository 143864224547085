import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "@/shared/components/Badge";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import BasicTableData from "./BasicTableData";
import styled from "styled-components";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";

const { tableHeaderResponsiveData } = BasicTableData();

const ChatbotAverageTime = ({ cardSubTitle, cardTitle }) => {
  const { t } = useTranslation("common");
  const [averTime, setAverTime] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(7);
  const [pageOption, setPageOption] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getAverageTimePersona = () => {
    let token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/persona_performance`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setAverTime(
          res.data.slice(offset * pageSize, offset * pageSize + pageSize),
        );
        setTotalCount(res.data.length);
      });
  };

  useEffect(() => {
    getAverageTimePersona();
  }, [offset, pageSize]);

  useEffect(() => {
    setOffset(0);
  }, [pageSize]);

  useEffect(() => {
    setPageOption([
      ...Array(Math.floor((totalCount - 1) / pageSize) + 1).keys(),
    ]);
  }, [pageSize, totalCount]);

  return (
    <Col lg={6} xl={6} xs={12} md={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{cardTitle}</CardTitle>
            <CardSubhead>{cardSubTitle}</CardSubhead>
          </CardTitleWrap>
          <Table responsive bordered>
            <thead>
              <tr>
                {tableHeaderResponsiveData.map((item, index) => (
                  <th key={index}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {averTime.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    {item.response_time ? item.response_time.toFixed(2) : 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationStyle>
            <ReactTablePagination
              page={averTime}
              gotoPage={(value) => {
                setOffset(value);
              }}
              previousPage={() => {
                setOffset(offset - 1);
              }}
              nextPage={() => {
                setOffset(offset + 1);
              }}
              canPreviousPage={offset > 0 ? true : false}
              canNextPage={
                offset === pageOption[pageOption.length - 1] ? false : true
              }
              pageOptions={pageOption}
              pageIndex={offset}
              pageSize={pageSize}
              setPageSize={setPageSize}
              manualPageSize={[7, 14, 21, 28]}
              dataLength={totalCount}
            />
          </PaginationStyle>
        </CardBody>
      </Card>
    </Col>
  );
};

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 14px;
`;

export default ChatbotAverageTime;
