const BasicTableData = () => {
  const header = [
    { accessor: "select", Header: "#" },
    { accessor: "id", Header: "id" },
    { accessor: "image", Header: "Avatar" },
    { accessor: "user_name", Header: "Name" },
    { accessor: "email", Header: "Email" },
    { accessor: "birthday", Header: "Birthday" },
    { accessor: "identity", Header: "Identity" },
    { accessor: "interests", Header: "Interests" },
    { accessor: "status", Header: "Status" },
    { accessor: "action", Header: "Action" },
  ];

  const basicTableData = {
    tableHeaderData: header,
  };
  return basicTableData;
};

export default BasicTableData;
