const BasicTableData = () => {
  const header = [
    { accessor: "id", Header: "#" },
    { accessor: "question", Header: "Question" },
    { accessor: "action", Header: "Action" },
  ];

  const basicTableData = {
    tableHeaderData: header,
  };
  return basicTableData;
};

export default BasicTableData;
