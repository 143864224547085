import React, { useEffect, useState } from "react";
import { Button } from "@/shared/components/Button";
import { Col, Row, Container } from "react-bootstrap";
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { useForm, Controller } from "react-hook-form";
import { FileInputField } from "@/shared/components/form/FileInput";
import { Card, CardBody } from "@/shared/components/Card";
import styled from "styled-components";
import CloseIcon from "mdi-react/CloseIcon";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import ProgressBar from "@/shared/components/ProgressBar";

const EditTrainItem = ({ onSubmit, editData, editProgress, editLoading }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [knowLedgeList, setKnowLedgeList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [urls, setUrls] = useState([]);
  const [trainProgress, setTrainProgress] = useState(0);

  useEffect(() => {
    if (editData) {
      setKnowLedgeList(editData.knowledge_base_files);
      setQuestionList(editData.question_files);
      setValue("knowledge_retrain", []);
      setValue("question_retrain", []);
      setUrls(editData.urls);
    }
  }, [editData, setValue]);

  useEffect(() => {
    if (editProgress !== undefined) {
      setTrainProgress(editProgress);
    }
  }, [editProgress]);

  const removeUrl = (id) => {
    const updatedUrls = urls.filter((url, index) => index !== id);
    setUrls(updatedUrls);
  };

  const handleUrlChange = (id, value) => {
    const updatedUrls = urls.map((url, index) => {
      if (index === id) {
        return { file_name: value, file_path: value };
      }
      return url;
    });
    setUrls(updatedUrls);
  };

  const onSubmitForm = (data) => {
    data.knowledge_base_files = knowLedgeList.map(
      (knowledge) => knowledge.file_name,
    );
    data.question_files = questionList.map((question) => question.file_name);
    console.log(urls);
    data.url_retrain = urls.map((url) => url.file_name);
    onSubmit(data);
  };

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <FormContainer onSubmit={handleSubmit(onSubmitForm)}>
                <FormGroup>
                  <FormGroupLabel>Public Website URLs</FormGroupLabel>
                  <label className="text-secondary">
                    Paste in as many links as you want to website pages, news
                    articles, blog posts, Wikipedia pages, or any other public
                    web page you think will be useful in training your Persona.
                    Paste one link per line.
                  </label>
                  <FormGroupField>
                    <UrlsGroup>
                      {urls &&
                        urls.map((url, index) => (
                          <UrlInput>
                            <input
                              type="text"
                              value={url.file_name}
                              onChange={(e) =>
                                handleUrlChange(index, e.target.value)
                              }
                            />
                            <SuccessLabel>{"Complete"}</SuccessLabel>
                            <TrashOutlineIcon
                              className="delete-style"
                              onClick={() => removeUrl(index)}
                            />
                          </UrlInput>
                        ))}
                    </UrlsGroup>
                  </FormGroupField>
                  <AddUrlButton
                    className="mt-2"
                    onClick={() => setUrls([...urls, { value: "" }])}
                  >
                    + Add Urls
                  </AddUrlButton>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Knowledge Base</FormGroupLabel>
                  <TagGroupStyle>
                    {knowLedgeList &&
                      knowLedgeList.map((file, index) => (
                        <TagLabelStyle key={index}>
                          <LabelStyle>
                            <FileLabel>{file.file_name}</FileLabel>
                            <SuccessLabel>{"Complete"}</SuccessLabel>
                          </LabelStyle>
                          <CloseIcon
                            size={16}
                            className="cursor-point"
                            onClick={() => {
                              setKnowLedgeList(
                                knowLedgeList.filter(
                                  (item) => item.file_name !== file.file_name,
                                ),
                              );
                            }}
                          />
                        </TagLabelStyle>
                      ))}
                  </TagGroupStyle>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Questions & Answers</FormGroupLabel>
                  <TagGroupStyle>
                    {questionList &&
                      questionList.map((file, index) => (
                        <TagLabelStyle key={index}>
                          <LabelStyle>
                            <FileLabel>{file.file_name}</FileLabel>
                            <SuccessLabel>{"Complete"}</SuccessLabel>
                          </LabelStyle>
                          <CloseIcon
                            size={16}
                            className="cursor-point"
                            onClick={() => {
                              setQuestionList(
                                questionList.filter(
                                  (item) => item.file_name !== file.file_name,
                                ),
                              );
                            }}
                          />
                        </TagLabelStyle>
                      ))}
                  </TagGroupStyle>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Additional Knowledge Base</FormGroupLabel>
                  <label className="text-secondary">
                    Here's where you upload all of the information you have
                    about your persona to train it. i.e. Wikipedia pages, books
                    about certain topics, interviews, news articles, tweets, or
                    other public information(PDF, DOC, TXT 10MB limit per file).
                  </label>
                  <FormGroupField>
                    <Controller
                      name="knowledge_retrain"
                      control={control}
                      render={({ field }) => (
                        <FileInputField isMulti {...field} />
                      )}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>
                    Additional Questions & Answers
                  </FormGroupLabel>
                  <label className="text-secondary">
                    Upload questions a human might ask your persona along with
                    the answers your persona would give, to train it. i.e.
                    Question: What is your favorite outdoor activity and why?
                    Answer: I like to go on walks because it is relaxing, and I
                    can clear my mind. or Question: What is your favorite quote
                    and why? Answer: “Carpe Diem” means to seize the day, which
                    is my approach to life.{" "}
                    <a
                      href="https://docs.google.com/document/d/1DotPI3MDbEurTjWxhR58n2wjEZpfxtq_Mx3MBlyUThE/edit?usp=drive_link"
                      target="_blank"
                    >
                      Click here to see a template.{" "}
                    </a>
                    (PDF, DOC, TXT 10MB limit per file).
                  </label>
                  <FormGroupField>
                    <Controller
                      name="question_retrain"
                      control={control}
                      render={({ field }) => (
                        <FileInputField {...field} multiple />
                      )}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Training Progress</FormGroupLabel>
                  <FormGroupField>
                    <div className="w-100">
                      <ProgressBar
                        now={trainProgress}
                        label={`${trainProgress}%`}
                        size="middle"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
                <div>
                  <Button variant="primary" type="submit">
                    ReTrain
                  </Button>
                  {editLoading ? (
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </FormContainer>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const TagLabelStyle = styled.div`
   {
    border: 1px solid;
    border-radius: 8px;
    padding: 5px;
    width: max-content;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
`;

const LabelStyle = styled.div`
   {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;

const FileLabel = styled.div`
   {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SuccessLabel = styled.div`
   {
    color: green;
  }
`;

const FailuareLabel = styled.div`
   {
    color: darkmagenta;
  }
`;

const TagGroupStyle = styled.div`
   {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
`;

const AddUrlButton = styled.div`
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  cursor: pointer;
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
`;

const UrlsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 75%;
`;

const UrlInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export default EditTrainItem;
