import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import Chat from "./Chat";
import myContacts from "./contacts";

const ChatCard = ({ personaInfo, currentUserInfo, type }) => {
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Chat
            contacts={myContacts}
            currentUser={{
              avatar: `${process.env.PUBLIC_URL}/img/ava.png` ?? "",
              name: currentUserInfo.name ?? "",
            }}
            personaInfo={personaInfo}
            type={type}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ChatCard;
