import React from "react";
import { useTranslation } from "react-i18next";
import Badge from "@/shared/components/Badge";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import BasicTableData from "./BasicTableData";

const { tableHeaderAnaylzeData, tableRowsData } = BasicTableData();

const AnaylzePersonas = ({ cardSubTitle, cardTitle }) => {
  const { t } = useTranslation("common");

  return (
    <Col lg={6} xl={6} md={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{cardTitle}</CardTitle>
            <CardSubhead>{cardSubTitle}</CardSubhead>
          </CardTitleWrap>
          <Table responsive bordered>
            <thead>
              <tr>
                {tableHeaderAnaylzeData.map((item, index) => (
                  <th key={index}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRowsData.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.chatbot_name}</td>
                  <td>{item.accuracy}</td>
                  <td>{item.engagement}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AnaylzePersonas;
