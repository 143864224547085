import React from "react";
import { useTranslation } from "react-i18next";
import Badge from "@/shared/components/Badge";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import BasicTableData from "./BasicTableData";

const { tableHeaderData } = BasicTableData();

const AiFriendsTable = ({ data }) => {
  const { t } = useTranslation("common");

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{t("app_dashboard.ai_friend")}</CardTitle>
          </CardTitleWrap>
          <Table bordered responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item, index) => (
                  <th key={index}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.total_message_count}</td>
                  <td>{item.daily_message_average}</td>
                  <td>
                    {
                      new Date(item.last_message_date)
                        .toISOString()
                        .split("T")[0]
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AiFriendsTable;
