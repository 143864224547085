import React, { useEffect } from "react";
import { Button } from "@/shared/components/Button";
import { Col, Row, Container } from "react-bootstrap";
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { FormField } from "@/containers/Form/ReactHookForm/FormHookValidation/components/FormField";
import { useForm } from "react-hook-form";
import { Card, CardBody } from "@/shared/components/Card";
import styled from "styled-components";
import renderToggleButtonField from "@/shared/components/form/ToggleButton";

const EditItem = ({ onSubmit, editData }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (editData) {
      setValue("personality", editData.personality);
      setValue("instructions", editData.instructions);
      setValue("has_base_persona", editData.has_base_persona);
    }
  }, [editData, setValue]);

  const onSubmitForm = (data) => {
    onSubmit(data);
  };

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <FormContainer onSubmit={handleSubmit(onSubmitForm)}>
                <FormGroup>
                  <FormGroupLabel>Personality</FormGroupLabel>
                  <FormGroupField>
                    <FormField
                      name="personality"
                      control={control}
                      component="textarea"
                      errors={errors}
                      rules={{ required: "This is required field" }}
                      defaultValue=""
                      placeholder="Create a character profile for the AI persona, detailing their expertise, personality, and user interaction. Focus on areas like type of knowledge, enthusiasm, and vocabulary style. Your aim is to clearly represent the persona's traits and how they engage with users. For example, for a Gaming Expert and Coach, highlight their extensive gaming knowledge, list of skills, passion for gaming, and personalized coaching approach."
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>System Instructions</FormGroupLabel>
                  <FormGroupField>
                    <FormField
                      name="instructions"
                      control={control}
                      component="textarea"
                      errors={errors}
                      rules={{
                        required: "This is required field",
                      }}
                      defaultValue=""
                      placeholder=""
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Persona Turn On/Off</FormGroupLabel>
                  <FormGroupField>
                    <FormField
                      name="has_base_persona"
                      component={renderToggleButtonField}
                      control={control}
                    />
                  </FormGroupField>
                </FormGroup>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </FormContainer>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditItem;

const TagLabelStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TagGroup = styled.div`
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Tag = styled.div`
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`;

const TagSelect = styled.div`
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  width: max-content;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  cursor: pointer;
  background-color: white;
`;
