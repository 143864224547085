import React, { useEffect } from "react";
import { Col } from "react-bootstrap";

const ChatCard = ({ personaInfo, currentUserInfo, type }) => {
  useEffect(() => {
    if (!personaInfo.id) return;
    // define a unique id for the script
    const scriptId = "widget-script";

    // get the existing script element by its id
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
      // if it exists, just update the chatAppKey
      existingScript.dataset.chatAppKey = personaInfo.id;
    } else {
      // if not, create a new script element
      const script = document.createElement("script");

      // set its id to easily find it later
      script.id = scriptId;

      // set its src to the script location
      script.src =
        "https://vengoaistorage.blob.core.windows.net/script-container/widget-prod.js";

      // make it async
      script.async = true;

      // add custom data attributes
      script.dataset.chatAppId = "VengoAI";
      script.dataset.chatAppKey = personaInfo.id;
      script.dataset.chatWidgetColor = personaInfo.badge_bg_color;

      // append the script to the body
      document.body.appendChild(script);

      // remove the script on component unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [personaInfo]);

  return <Col md={12} lg={12} />;
};

export default ChatCard;
