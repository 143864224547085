import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal as BootstrapModal } from "react-bootstrap";
import { Button, ButtonToolbar } from "@/shared/components/Button";
import styled from "styled-components";
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from "@/utils/palette";
import {
  flexFlow,
  left,
  paddingLeft,
  paddingRight,
  right,
} from "@/utils/directions";
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { useForm, Controller } from "react-hook-form";
import { FormField } from "@/containers/Form/ReactHookForm/FormHookValidation/components/FormField";
import { emailPatter } from "@/shared/helpers";
import { SelectField } from "@/shared/components/form/Select";
import PasswordField from "@/shared/components/form/Password";

const Modal = ({
  color,
  title,
  colored,
  header,
  onClose,
  open,
  onSubmit,
  editData,
  roleList,
  isCreate,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [status, setStatus] = useState(0);

  useEffect(() => {
    if (!open) return;

    if (editData) {
      setValue("name", editData.name);
      setValue("email", editData.email);
      setValue("role", [{ value: editData.role, label: editData.role_title }]);
      setStatus(editData.status);
    }

    return () => {
      reset({
        name: null,
        email: null,
        role: null,
        password: null,
      });
    };
  }, [editData, open, reset, setValue]);

  const onSubmitForm = (data) => {
    data.status = status ?? 1;
    onSubmit(data);
  };

  return (
    <StyledModal
      show={open}
      onHide={onClose}
      color={color}
      colored={colored}
      header={header}
    >
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalCloseButton
          className="lnr lnr-cross"
          aria-label="close-btn"
          type="button"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <FormContainer onSubmit={handleSubmit(onSubmitForm)}>
          <FormGroup>
            <FormGroupLabel>Name</FormGroupLabel>
            <FormGroupField>
              <FormField
                name="name"
                control={control}
                component="input"
                errors={errors}
                rules={{ required: "This is required field" }}
                defaultValue=""
                placeholder="Name"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>E-mail</FormGroupLabel>
            <FormGroupField>
              <FormField
                name="email"
                control={control}
                component="input"
                errors={errors}
                rules={{
                  required: "This is required field",
                  pattern: {
                    value: emailPatter,
                    message: "Entered value does not match email format",
                  },
                }}
                defaultValue=""
                placeholder="example@gmail.com"
              />
            </FormGroupField>
          </FormGroup>
          {isCreate ? (
            <FormGroup>
              <FormGroupLabel>Password</FormGroupLabel>
              <FormGroupField>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PasswordField
                      input={field}
                      meta={{
                        touched: !!fieldState.error,
                        error: fieldState.error?.message,
                      }}
                      placeholder="Password"
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    minLength: {
                      value: 6,
                      message: "Minimum password is 6 characters",
                    },
                  }}
                  defaultValue=""
                />
              </FormGroupField>
            </FormGroup>
          ) : (
            <></>
          )}
          <FormGroup>
            <FormGroupLabel>Role</FormGroupLabel>
            <FormGroupField>
              <FormField
                name="role"
                control={control}
                component={SelectField}
                errors={errors}
                options={roleList}
                rules={{ required: "This is required field" }}
                defaultValue=""
              />
            </FormGroupField>
          </FormGroup>
          <ModalFooter>
            <Button variant="secondary" type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </FormContainer>
      </ModalBody>
    </StyledModal>
  );
};
Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  color: PropTypes.string,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  editData: PropTypes.shape(),
};

Modal.defaultProps = {
  title: "Modal",
  color: "",
  colored: false,
  header: false,
  editData: {},
};

export default Modal;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  justify-content: end;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  text-align: left;

  &:first-child {
    margin-top: 0;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case "primary":
      return colorBlue;
    case "success":
      return colorAccent;
    case "warning":
      return colorYellow;
    case "danger":
      return colorRed;

    default:
      return colorText;
  }
};

const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: (prop) => !["colored", "header"].includes(prop),
})`
  .modal-dialog {
    max-width: 385px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 32px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${(props) => getColor(props.color)};
  }

  ${(props) =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${(props) =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;
