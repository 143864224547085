import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FileEditOutlineIcon from "mdi-react/FileEditOutlineIcon";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import toast from "react-hot-toast";
import Input from "../../shared/components/Input";
import QuestionsData from "./QuestionsData";
import EditItemModal from "./components/EditItemModal";
import ImportModal from "./components/ImportModal";
import useFullLoading from "../../shared/hooks/store/useLoading";
import DeleteModal from "../Members/components/DeleteModal";
import { Table } from "@/shared/components/TableElements";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";

const Questions = () => {
  const { setLoading } = useFullLoading();
  const { tableHeaderData } = QuestionsData();
  const [questionsList, setQuestionsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState();
  const [disable, setDisable] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageOption, setPageOption] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [keywords, setKeywords] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openImportModal, setOpenImportModal] = useState(false);

  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetchQuestions = () => {
    let token = localStorage.getItem("token");
    try {
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/iq_question_manage/get_iq_questions`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_keyword: searchValue,
            page_index: offset,
            show_count: pageSize,
          }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          setQuestionsList(res.data);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const handleDeleteQuestion = (id) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/iq_question_manage/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            toast.success("success!");
            handleFetchQuestions();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const handleEditQuestion = (data) => {
    setEditData(data);
    setOpenModal(true);
  };

  const handleSaveQuestion = useCallback(
    async (data) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        editData
          ? fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/iq_question_manage/update/${editData.id}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              },
            )
              .then((res) => res.json())
              .then((res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  handleFetchQuestions();
                }
              })
              .finally(() => {
                setLoading(false);
              })
          : fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/iq_question_manage/create`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              },
            )
              .then((res) => res.json())
              .then((res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  handleFetchQuestions();
                }
              })
              .finally(() => {
                setLoading(false);
              });
        setOpenModal(false);
        setEditData(null);
      } catch (err) {
        toast.error(err);
      }
    },
    [editData, handleFetchQuestions, setLoading],
  );

  const handleImportQuestion = (data) => {
    let token = localStorage.getItem("token");
    if (data.question.length) {
      try {
        setLoading(true);
        setOpenImportModal(false);
        const formData = new FormData();
        formData.append("file", data.question[0].file);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/iq_question_manage/create_with_file`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          },
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.status === "success") {
              toast.success("success!");
              handleFetchQuestions();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.error(err);
      }
    }
  };

  useEffect(() => {
    handleFetchQuestions();
  }, [offset, pageSize, searchValue]);

  useEffect(() => {
    if (keywords.length === 0) {
      setSearchValue("");
    }
    const interval = setInterval(() => {
      setSearchValue(keywords);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [keywords]);

  useEffect(() => {
    setPageOption([
      ...Array(Math.floor((totalCount - 1) / pageSize) + 1).keys(),
    ]);
  }, [pageSize, totalCount]);

  useEffect(() => {
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setDisable(result.data.role_permissions);
    });
  }, []);

  const activeColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <FileEditOutlineIcon
          onClick={() => {
            handleEditQuestion(data);
          }}
          role="button"
        />
        <TrashOutlineIcon
          onClick={() => {
            setOpenDeleteModal(true);
            setDeleteId(data.id);
          }}
          role="button"
        />
      </div>
    ),
    [handleEditQuestion, disable],
  );

  const tableData = useMemo(
    () =>
      questionsList.map((item) => ({
        ...item,
        action: activeColumn(item),
      })),
    [activeColumn, questionsList],
  );

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Card>
          <CardBody>
            <StyledHeader>
              <CardTitleWrap>
                <CardTitle>Questions</CardTitle>
              </CardTitleWrap>
              <SearchHeader>
                <StyleSearch>
                  <LabelStyle>
                    <label className="text-light pt-2" htmlFor="search">
                      <CardTitle>Search: </CardTitle>
                    </label>
                  </LabelStyle>
                  <InputStyle>
                    <Input
                      id="search"
                      type="text"
                      placeholder="e.g.How are you today?"
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          setKeywords(e.target.value);
                        }
                      }}
                      onChange={(e) => {
                        setKeywords(e.target.value);
                      }}
                    />
                  </InputStyle>
                </StyleSearch>
                <ButtonGroup>
                  <StyledButton
                    variant="primary"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Add Question
                  </StyledButton>
                  <StyledButton
                    variant="primary"
                    onClick={() => {
                      setOpenImportModal(true);
                    }}
                  >
                    Import
                  </StyledButton>
                </ButtonGroup>
              </SearchHeader>
            </StyledHeader>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        {tableHeaderData.map((item, index) => (
                          <th key={index}>{item.Header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr key={index}>
                          <td>{pageSize * offset + index + 1}</td>
                          <td>{item.question}</td>
                          <td>{item.action}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <PaginationStyle>
                    <ReactTablePagination
                      page={questionsList}
                      gotoPage={(value) => {
                        setOffset(value);
                      }}
                      previousPage={() => {
                        setOffset(offset - 1);
                      }}
                      nextPage={() => {
                        setOffset(offset + 1);
                      }}
                      canPreviousPage={offset > 0 ? true : false}
                      canNextPage={
                        offset === pageOption[pageOption.length - 1]
                          ? false
                          : true
                      }
                      pageOptions={pageOption}
                      pageIndex={offset}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      manualPageSize={[10, 20, 30, 40]}
                      dataLength={totalCount}
                    />
                  </PaginationStyle>
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </Row>
      <EditItemModal
        title="Question"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
        onSubmit={handleSaveQuestion}
        editData={editData}
      />
      <ImportModal
        title="Import File..."
        open={openImportModal}
        onClose={() => {
          setOpenImportModal(false);
        }}
        onSubmit={handleImportQuestion}
      />
      <DeleteModal
        open={openDeleteModal}
        title={"Are you sure you want to delete this question?"}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteQuestion(deleteId);
        }}
      />
    </Container>
  );
};

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
  margin-bottom: 30px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 14px;
`;

const InputStyle = styled.div`
  width: 200px;
`;

const LabelStyle = styled.div`
  width: 55px;
`;

const StyleSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const SearchHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  @media screen and (max-width: 490px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export default Questions;
