import React from "react";
import PropTypes from "prop-types";
import withAuthFirebase from "@/shared/components/account/auth/withAuthFirebase";
import LogInForm from "@/shared/components/account/loginForm/LogInForm";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from "@/shared/components/account/AccountElements";
import { useHistory } from "react-router-dom";

const LogIn = () => {
  const history = useHistory();
  const handleLogin = (value) => {
    const username = value.username;
    const password = value.password;
    if (username && password) {
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/admin_signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: username, password: password }),
        },
      )
        .then((data) => data.json())
        .then((result) => {
          console.log(result);
          if (result.status !== "success") {
            alert("Invalid email or password");
            return;
          } else {
            if (
              typeof window !== undefined &&
              typeof document !== undefined &&
              typeof localStorage !== undefined
            ) {
              // Perform localStorage action
              localStorage.setItem("token", result.token);
            }
            history.push("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>
              Welcome to
              <AccountLogo>
                VengoAI <AccountLogoAccent>Admin</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            <h4 className="subhead">Start your business easily</h4>
          </AccountHead>
          <LogInForm onSubmit={handleLogin} form="log_in_form" />
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LogIn);
