import React, { useState, useEffect, useRef } from "react";
import ProgressBar from "./ProgressBar";
import PlayPauseIcon from "./PlayPauseIcon";
import styled from "styled-components";

const AudioPlayer = ({ src }) => {
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioElement = useRef(null);

  // Update progressBar with current time
  const updateTime = (e) => {
    if (!duration) {
      const audio = audioElement.current;
      setDuration(audio.duration);
    }
    setCurrentTime(e.target.currentTime);
    e.target.currentTime === duration && setIsPlaying(false);
  };

  useEffect(() => {
    const audio = audioElement.current;
    audio.addEventListener("timeupdate", updateTime);

    //cleanup function
    return () => {
      audio.removeEventListener("timeupdate", updateTime);
    };
  }, []);

  useEffect(() => {
    const audio = audioElement.current;
    setDuration(audio.duration);
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      audioElement.current.play();
    } else {
      audioElement.current.pause();
    }
  }, [isPlaying]);

  return (
    <StyledAudioWrapper>
      <audio src={src} ref={audioElement} />
      <PlayPauseIcon
        isPlaying={isPlaying}
        onClick={() => setIsPlaying((prev) => !prev)}
      />
      <ProgressBar duration={duration} currentTime={currentTime} />
    </StyledAudioWrapper>
  );
};

const StyledAudioWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export default AudioPlayer;
