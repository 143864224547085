import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Panel from "@/shared/components/Panel";
import getTooltipStyles from "@/shared/helpers";
import styled from "styled-components";

const PopularPersonas = ({ dir, popularPersonas }) => {
  const { t } = useTranslation("common");

  const themeName = useSelector((state) => state.theme.className);

  return (
    <Panel
      lg={6}
      xl={6}
      md={12}
      title={"Most popular personas"}
      // subhead="How effective your business is in comparison with the previous month"
    >
      <ClicksChartWrap dir="ltr">
        <ResponsiveContainer height={300}>
          <BarChart
            width={600}
            height={220}
            data={popularPersonas}
            layout="vertical"
            barGap={0}
            barCategoryGap={0}
            stackOffset="expand"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" hide reversed={dir === "rtl"} />
            <YAxis
              type="category"
              dataKey="name"
              tickLine={false}
              verticalAnchor="start"
              orientation={dir === "rtl" ? "right" : "left"}
            />
            <Tooltip {...getTooltipStyles(themeName, "defaultItems")} />
            <Bar dataKey="purchased" fill="#48b5ff" barSize={12} />
            <Bar dataKey="used" fill="#7edbff" barSize={12} />
          </BarChart>
        </ResponsiveContainer>
      </ClicksChartWrap>
    </Panel>
  );
};

PopularPersonas.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default PopularPersonas;

// region STYLES

const ClicksChartWrap = styled.div`
  margin-top: -30px;
`;

// endregion
