const BasicTableData = () => {
  const header = [
    { id: "1", Header: "#" },
    { id: "2", Header: "Avatar" },
    { id: "3", Header: "Creator" },
    { id: "4", Header: "Name" },
    { id: "5", Header: "Progress" },
    { id: "6", Header: "Bio" },
    { id: "7", Header: "Personality" },
    { id: "8", Header: "Interest" },
    { id: "9", Header: "Action" },
  ];

  const basicTableData = {
    tableHeaderData: header,
  };
  return basicTableData;
};

export default BasicTableData;
