const BasicTableData = () => {
  const creditHeader = [
    { id: 1, title: "#" },
    { id: 2, title: "Username" },
    { id: 3, title: "Current Credit" },
    { id: 4, title: "Earned Credit" },
    { id: 4, title: "Spent Credit" },
  ];

  const creditData = [
    {
      id: 1,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
    {
      id: 2,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
    {
      id: 3,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
    {
      id: 4,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
    {
      id: 5,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
    {
      id: 6,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
    {
      id: 7,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
    {
      id: 8,
      username: "blackspider8391",
      current_credit: "100",
      earned_credit: "300",
      spent_credit: "500",
    },
  ];

  const historyHeader = [
    { id: 1, title: "#" },
    { id: 2, title: "Username" },
    { id: 3, title: "Amount" },
    { id: 4, title: "Description" },
    { id: 4, title: "Status" },
    { id: 5, title: "Date" },
  ];

  const historyData = [
    {
      id: 1,
      username: "blackspider8391",
      amount: "$300",
      description: "Buy credits",
      status: "Success",
      time: "2023-12-27",
    },
    {
      id: 2,
      username: "blackspider8391",
      amount: "$50",
      description: "Buy credits",
      status: "Success",
      time: "2023-12-27",
    },
    {
      id: 3,
      username: "blackspider8391",
      amount: "$300",
      description: "Buy credits",
      status: "Failed",
      time: "2023-12-27",
    },
    {
      id: 4,
      username: "blackspider8391",
      amount: "$300",
      description: "Buy credits",
      status: "Pending",
      time: "2023-12-27",
    },
    {
      id: 5,
      username: "blackspider8391",
      amount: "$500",
      description: "Buy credits",
      status: "Success",
      time: "2023-12-27",
    },
    {
      id: 6,
      username: "blackspider8391",
      amount: "$100",
      description: "Buy credits",
      status: "Success",
      time: "2023-12-27",
    },
    {
      id: 7,
      username: "blackspider8391",
      amount: "$30",
      description: "Buy credits",
      status: "Canceled",
      time: "2023-12-27",
    },
    {
      id: 8,
      username: "blackspider8391",
      amount: "$10",
      description: "Buy credits",
      status: "Success",
      time: "2023-12-27",
    },
  ];

  return {
    creditHeader,
    creditData,
    historyHeader,
    historyData,
  };
};

export default BasicTableData;
