import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";

import styled from "styled-components";

const MessageStatisticsChartBox = styled.div`
  .chartjs-render-monitor {
    max-height: 300px;
    min-height: 300px;
  }
`;

const MessageStatisticsChart = ({ monthsMessages, monthsMessagesCount }) => {
  const { t } = useTranslation("common");

  const data = useMemo(
    () => ({
      labels: monthsMessages,
      datasets: [
        {
          label: "Message",
          fill: false,
          lineTension: 0.3,
          backgroundColor: "#36A2EB",
          borderColor: "#36A2EB",
          borderWidth: 2,
          pointBackgroundColor: "#36A2EB",
          pointHoverRadius: 3,
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          data: monthsMessagesCount,
        },
      ],
    }),
    [monthsMessagesCount, monthsMessages],
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgb(204, 204, 204)",
            borderDash: [3, 3],
          },
          ticks: {
            fontColor: "rgb(204, 204, 204)",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgb(204, 204, 204)",
            borderDash: [3, 3],
          },
          ticks: {
            fontColor: "rgb(204, 204, 204)",
          },
        },
      ],
    },
  };

  return (
    <Col lg={6} xl={9} md={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{"Message Statistics"}</CardTitle>
          </CardTitleWrap>
          <MessageStatisticsChartBox>
            <Line data={data} options={options} />
          </MessageStatisticsChartBox>
        </CardBody>
      </Card>
    </Col>
  );
};

export default MessageStatisticsChart;
