import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import { Card, CardBody, CardTitle } from "@/shared/components/Card";
import toast from "react-hot-toast";
import MessagesData from "../MessagesData";
import useFullLoading from "../../../shared/hooks/store/useLoading";
import Input from "@/shared/components/Input";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import DeleteModal from "../../Members/components/DeleteModal";
import { Table } from "@/shared/components/TableElements";
import ImageModal from "./ImageModal";

const { tableImageHeaderData } = MessagesData();
const TextMessages = () => {
  const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
  const { setLoading } = useFullLoading();
  const [globalMessageList, setGlobalMessageList] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [pageOption, setPageOption] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState([]);
  const [editData, setEditData] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetchGlobalMessages = useCallback(
    async (value) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/notification_manage/get_notifications`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              search_keyword: value ?? "",
              page_index: offset,
              show_count: pageSize,
              filters: {
                content_types: ["image"],
              },
            }),
          },
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.status === "success") {
              setGlobalMessageList(res.data);
              setTotalCount(res.total);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.success(err);
      }
    },
    [setLoading, offset, pageSize],
  );

  const handleDeleteMessage = useCallback(
    async (id) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/notification_manage/delete/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
          .then((res) => res.json())
          .then(async (res) => {
            if (res.status === "success") {
              toast.success("success!");
              await handleFetchGlobalMessages();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.error(err);
      }
    },
    [setLoading, handleFetchGlobalMessages],
  );

  const actionColumn = useCallback(
    (data) => (
      <TrashOutlineIcon
        onClick={(e) => {
          if (disable.length)
            if (disable[14].delete) {
              e.stopPropagation();
              setOpenDeleteModal(true);
              setDeleteId(data.id);
            }
        }}
        style={{
          cursor: disable.length
            ? disable[14].delete
              ? "pointer"
              : "not-allowed"
            : "",
          opacity: disable.length ? (disable[14].delete ? 1 : 0.5) : "",
        }}
        role="button"
      />
    ),
    [disable],
  );

  const tableData = useMemo(
    () =>
      globalMessageList &&
      globalMessageList.map((item, index) => ({
        ...item,
        id: index + 1,
        action: actionColumn(item),
        image: <Image src={item.file_path} style={{ width: "50px" }} alt="" />,
      })),
    [globalMessageList],
  );

  const handleSaveMessage = useCallback(
    async (data) => {
      setOpenModal(false);
      let token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("content_type", "image");
      if (data.files[0] instanceof File) {
        formData.append("file", data.files[0]);
      }
      formData.append("title", data.title);
      formData.append("content", data.content);
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/notification_manage/post`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          },
        )
          .then((res) => res.json())
          .then(async (res) => {
            if (res.status === "success") {
              toast.success("success!");
              await handleFetchGlobalMessages();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.error(err);
      }
    },
    [handleFetchGlobalMessages, setLoading],
  );

  useEffect(() => {
    if (keywords.length === 0) {
      setSearchValue("");
    }
    const interval = setInterval(() => {
      setSearchValue(keywords);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [keywords]);

  useEffect(() => {
    setPageOption([
      ...Array(Math.floor((totalCount - 1) / pageSize) + 1).keys(),
    ]);
  }, [pageSize, totalCount]);

  useEffect(() => {
    handleFetchGlobalMessages(searchValue);
  }, [handleFetchGlobalMessages, searchValue, offset, pageSize]);

  useEffect(() => {
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setDisable(result.data.role_permissions);
    });
  }, []);

  return (
    <Card>
      <CardBody>
        <Row>
          <StyledHeader>
            <FilterStyle>
              <StyleSearch>
                <LabelStyle>
                  <label className="text-light pt-2" htmlFor="search">
                    <CardTitle>Search: </CardTitle>
                  </label>
                </LabelStyle>
                <InputStyle>
                  <Input
                    id="search"
                    type="text"
                    placeholder="e.g.Alex Chen"
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        setKeywords(e.target.value);
                      }
                    }}
                    onChange={(e) => {
                      setKeywords(e.target.value);
                    }}
                  />
                </InputStyle>
              </StyleSearch>
            </FilterStyle>
            <StyledButton
              variant="primary"
              onClick={() => {
                if (disable.length)
                  if (disable[14].write) {
                    setEditData(null);
                    setOpenModal(true);
                  }
              }}
              style={{
                cursor: disable.length
                  ? disable[14].write
                    ? "pointer"
                    : "not-allowed"
                  : "",
                opacity: disable.length ? (disable[14].write ? 1 : 0.5) : "",
              }}
            >
              Send Image
            </StyledButton>
          </StyledHeader>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      {tableImageHeaderData.map((item, index) => (
                        <th key={index}>{item.Header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      tableData.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setEditData(item);
                            setOpenModal(true);
                          }}
                        >
                          <td>{item.id}</td>
                          <td>{item.image}</td>
                          <td className="text-break text-truncate table-item">
                            {item.title}
                          </td>
                          <td className="text-break text-truncate table-item">
                            {item.content}
                          </td>
                          <td>{item.action}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <PaginationStyle>
                  <ReactTablePagination
                    page={tableData}
                    gotoPage={(value) => {
                      setOffset(value);
                    }}
                    previousPage={() => {
                      setOffset(offset - 1);
                    }}
                    nextPage={() => {
                      setOffset(offset + 1);
                    }}
                    canPreviousPage={offset > 0 ? true : false}
                    canNextPage={
                      offset === pageOption[pageOption.length - 1]
                        ? false
                        : true
                    }
                    pageOptions={pageOption}
                    pageIndex={offset}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    manualPageSize={[10, 20, 30, 40]}
                    dataLength={totalCount}
                  />
                </PaginationStyle>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ImageModal
          title={editData ? "Image Message" : "New Image Message"}
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onSubmit={handleSaveMessage}
          editData={editData}
        />
        <DeleteModal
          open={openDeleteModal}
          title={"Are you sure you want to delete this message?"}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          onDelete={() => {
            setOpenDeleteModal(false);
            handleDeleteMessage(deleteId);
          }}
        />
      </CardBody>
    </Card>
  );
};

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 490px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
`;

const StyleSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const FilterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 1240px) {
    flex-direction: column;
  }
`;

const InputStyle = styled.div`
  width: 200px;
`;

const LabelStyle = styled.div`
  width: 55px;
`;

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 14px;
`;

const Image = styled.img`
  max-width: 70px;
  max-height: 70px;
  border-radius: 55px;
`;

export default TextMessages;
