import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";

const AgeUsersDoughnut = ({ memberAge }) => {
  const { t } = useTranslation("common");

  const data = useMemo(
    () => ({
      labels: ["18 to 24", "25 to 34", "35 to 44"],
      datasets: [
        {
          data: [
            memberAge?.age_18_24,
            memberAge?.age_25_34,
            memberAge?.age_35_44,
          ],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          borderColor: "rgba(255,255,255,0.54)",
        },
      ],
    }),
    [memberAge],
  );

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{t("app_dashboard.ages_of_users")}</CardTitle>
          </CardTitleWrap>
          <div>
            <Doughnut data={data} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AgeUsersDoughnut;
