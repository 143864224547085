import styled from "styled-components";
import ChatBox from "./ChatBox";

export default function ChatContent({ messages, style }) {
  return (
    <StyledChatContent>
      {messages.map((item, index) => (
        <ChatBox content={item} key={index} style={style} />
      ))}
    </StyledChatContent>
  );
}

const StyledChatContent = styled.div`
  height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  overflow-y: auto;
  scroll-behavior: smooth;
  background-color: #f5f8fb;
`;
