import { atom, useAtom } from "jotai";

const loadingAtom = atom(false);

const useFullLoading = () => {
  const [loading, setLoading] = useAtom(loadingAtom);
  
  return {
    loading,
    setLoading,
  };
};

export default useFullLoading;
