import styled from "styled-components";
import {
  colorAccent,
  colorAdditional,
  colorBackgroundBody,
  colorBorder,
  colorFieldsBorder,
  colorText,
} from "@/utils/palette";

export default function (props) {
  return <StyledInput {...props} />;
}

const StyledInput = styled.input`
  width: 100%;
  padding: 5px 10px;
  font-size: 12px;
  height: 38px;
  transition: border 0.3s;
  background: transparent;
  border: 1px solid ${colorFieldsBorder};
  color: ${colorText};

  &::-webkit-input-placeholder {
    color: ${colorAdditional};
  }
  &::-moz-placeholder {
    color: ${colorAdditional};
  }
  /* Firefox 19+ */
  &:-moz-placeholder {
    color: ${colorAdditional};
  }
  /* Firefox 18- */
  &:-ms-input-placeholder {
    color: ${colorAdditional};
  }

  &[disabled] {
    &:focus,
    &:active {
      border-color: ${colorBorder};
    }
  }

  &:focus,
  &:active {
    outline: none;
    border-color: ${colorAccent};
  }
`;
