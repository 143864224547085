const BasicTableData = () => {
  const header = [
    { id: 1, title: "#" },
    { id: 2, title: "UserName" },
    { id: 3, title: "Message" },
    { id: 4, title: "Personas Responses" },
    { id: 5, title: "IQ" },
  ];

  const headerResponsive = [
    { id: 1, title: "#" },
    { id: 2, title: "UserName" },
    { id: 3, title: "Message" },
    { id: 4, title: "Personas Responses" },
    { id: 5, title: "Age" },
    { id: 7, title: "Date" },
    { id: 8, title: "Location" },
    { id: 9, title: "Status" },
  ];

  const rows = [
    {
      id: 1,
      username: "Mario1",
      message: "655",
      personas_response: "650",
      iq: '50',
    },
    {
      id: 2,
      username: "Mario1",
      message: "655",
      personas_response: "650",
      iq: '50',
    },
    {
      id: 3,
      username: "Mario1",
      message: "655",
      personas_response: "650",
      iq: '50',
    },
    {
      id: 4,
      username: "Mario1",
      message: "655",
      personas_response: "650",
      iq: '50',
    },
    {
      id: 5,
      username: "Mario1",
      message: "655",
      personas_response: "650",
      iq: '50',
    },
    {
      id: 6,
      username: "Mario1",
      message: "655",
      personas_response: "650",
      iq: '50',
    },
    {
      id: 7,
      username: "Mario1",
      message: "655",
      personas_response: "650",
      iq: '50',
    },
  ];

  const basicTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
    tableRowsData: rows,
  };
  return basicTableData;
};

export default BasicTableData;
