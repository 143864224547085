"use client";

import { useEffect } from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import WrappedRoutes from "./Router/WrappedRoutes";

export default function AuthRedirectHandler() {
  const pathname = window.location.pathname;
  const history = useHistory();

  useEffect(() => {
    if (typeof window !== undefined) {
      const token = window.localStorage.getItem("token");

      if (!token) {
        history.push("/log_in");
      }
    }
  }, [pathname]);

  return (
    <Switch>
      <Route path="/" component={WrappedRoutes} />
    </Switch>
  );
}
