import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal as BootstrapModal } from "react-bootstrap";
import { Button, ButtonToolbar } from "@/shared/components/Button";
import styled from "styled-components";
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from "@/utils/palette";
import {
  flexFlow,
  left,
  paddingLeft,
  paddingRight,
  right,
} from "@/utils/directions";

const Modal = ({
  color,
  title,
  colored,
  header,
  onClose,
  open,
  onSubmit,
  editData,
}) => {
  const [iframe, setIframe] = useState("");
  const [script, setScript] = useState("");
  useEffect(() => {
    if (!editData) return;
    setIframe(editData.iframe);
    setScript(editData.script);
  }, [editData]);
  return (
    <StyledModal
      show={open}
      onHide={onClose}
      color={color}
      colored={colored}
      header={header}
    >
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalCloseButton
          className="lnr lnr-cross"
          aria-label="close-btn"
          type="button"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <div>Copy and add the following script to your website html:</div>
        <ScriptStyle>{script}</ScriptStyle>
        <Divider />
        <div>Or use iframe if you want to embed to specific section:</div>
        <ScriptStyle>{iframe}</ScriptStyle>
      </ModalBody>
    </StyledModal>
  );
};
Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  color: PropTypes.string,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  editData: PropTypes.shape(),
};

Modal.defaultProps = {
  title: "Modal",
  color: "",
  colored: false,
  header: false,
  editData: {},
};

export default Modal;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  justify-content: end;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`;

const Divider = styled.div`
   {
    border: 0.5px solid;
    border-color: #717171;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const ScriptStyle = styled.div`
   {
    text-align: start;
    margin-top: 4px;
    background-color: #414141;
    padding: 8px;
    word-break: break-all;
  }
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  text-align: left;

  &:first-child {
    margin-top: 0;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case "primary":
      return colorBlue;
    case "success":
      return colorAccent;
    case "warning":
      return colorYellow;
    case "danger":
      return colorRed;

    default:
      return colorText;
  }
};

const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: (prop) => !["colored", "header"].includes(prop),
})`
  .modal-dialog {
    max-width: 385px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 32px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${(props) => getColor(props.color)};
  }

  ${(props) =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${(props) =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;
