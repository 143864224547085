import React from "react";
import { useTranslation } from "react-i18next";
import Badge from "@/shared/components/Badge";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import BasicTableData from "./BasicTableData";

const { tableHeaderData, tableRowsData } = BasicTableData();

const KnowledgeTree = () => {
  const { t } = useTranslation("common");

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{"Knowledge & IQ"}</CardTitle>
            <CardSubhead>
              Knowledge Tree stats & IQ Level stats per user
            </CardSubhead>
          </CardTitleWrap>
          <Table bordered responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRowsData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.username}</td>
                  <td>{item.message}</td>
                  <td>{item.personas_response}</td>
                  <td>{item.iq}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

export default KnowledgeTree;
