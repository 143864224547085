import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FileEditOutlineIcon from "mdi-react/FileEditOutlineIcon";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import toast from "react-hot-toast";
import WallpapersData from "./WallpapersData";
import wallpaperApi from "../../utils/api/wallpaperApi";
import EditItemModal from "./components/EditItemModal";
import useFullLoading from "../../shared/hooks/store/useLoading";
import { Table } from "@/shared/components/TableElements";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
const { tableHeaderData } = WallpapersData();
const Wallpapers = () => {
  const { setLoading } = useFullLoading();
  const [wallpaperList, setWallpaperList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleFetchWallpaper = useCallback(async () => {
    try {
      setLoading(true);
      const res = await wallpaperApi.get();

      if (res.status === 200) {
        setWallpaperList(res.data.data);
      }
    } catch (err) {
      toast.error(err);
    }
    setLoading(false);
  }, [setLoading]);

  const handleDeleteWallpaper = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const res = await wallpaperApi.delete({ id });

        if (res.status === 200) {
          toast.success("success!");
          await handleFetchWallpaper();
        }
      } catch (err) {
        toast.error(err);
      }
      setLoading(false);
    },
    [handleFetchWallpaper, setLoading],
  );

  const handleEditWallpaper = useCallback((data) => {
    setEditData(data);
    setOpenModal(true);
  }, []);

  const handleSaveWallpaper = useCallback(
    async (data) => {
      setLoading(true);
      const func = editData ? wallpaperApi.update : wallpaperApi.create;
      const formData = new FormData();

      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("image", data.files[0]);
      if (editData) {
        formData.append("id", editData.id);
      }

      try {
        const res = await func(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          toast.success("success!");
          await handleFetchWallpaper();
        }
        setOpenModal(false);
        setEditData(null);
      } catch (err) {
        toast.error(err);
      }
      setLoading(false);
    },
    [editData, handleFetchWallpaper, setLoading],
  );

  // useEffect(() => {
  //   handleFetchWallpaper();
  // }, [handleFetchWallpaper]);

  const activeColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <FileEditOutlineIcon
          onClick={() => handleEditWallpaper(data)}
          role="button"
        />
        <TrashOutlineIcon
          onClick={() => handleDeleteWallpaper(data.id)}
          role="button"
        />
      </div>
    ),
    [handleDeleteWallpaper, handleEditWallpaper],
  );

  const tableData = useMemo(
    () =>
      wallpaperList.map((item, index) => ({
        ...item,
        id: index + 1,
        action: activeColumn(item),
        image: (
          <Image
            src={item.wallpaper_picture}
            style={{ width: "50px" }}
            alt=""
          />
        ),
      })),
    [activeColumn, wallpaperList],
  );

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Card>
          <CardBody>
            <StyledHeader>
              <CardTitleWrap>
                <CardTitle>App Backgrounds</CardTitle>
                <CardSubhead>List of App Background</CardSubhead>
              </CardTitleWrap>
              <StyledButton
                variant="primary"
                onClick={() => setOpenModal(true)}
              >
                Add New Wallpaper
              </StyledButton>
            </StyledHeader>
            <Col md={12}>
              <Table responsive hover>
                <thead>
                  <tr>
                    {tableHeaderData.map((item) => (
                      <th key={item.id}>{item.Header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.image}</td>
                        <td>{item.action}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </CardBody>
        </Card>
      </Row>
      <EditItemModal
        title="Add App Background"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
        onSubmit={handleSaveWallpaper}
        editData={editData}
      />
    </Container>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
  margin-bottom: 30px;
`;

const Image = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

export default Wallpapers;
