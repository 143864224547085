import styled, { keyframes } from "styled-components";
import AudioPlayer from "./AudioPlayer";

export default function ChatBox({ content, style }) {
  if (content.type === "AI") {
    return (
      <AIChatContent>
        {style?.persona_picture && (
          <StyledAIAvatar src={style.persona_picture} />
        )}
        {!content?.audio && (
          <StyledAIChatBox
            color={style?.ai_text_color}
            bgColor={style?.ai_bg_color}
          >
            <div dangerouslySetInnerHTML={{ __html: content.message }} />
          </StyledAIChatBox>
        )}

        {content?.audio && (
          <StyledAIChatBox
            color={style?.ai_text_color}
            bgColor={style?.ai_bg_color}
          >
            <AudioPlayer src={URL.createObjectURL(content.audio)} />
          </StyledAIChatBox>
        )}
      </AIChatContent>
    );
  }

  if (content.type === "LOAD") {
    return (
      <StyledAILoadWrapper
        color={style?.ai_text_color}
        bgColor={style?.ai_bg_color}
      >
        <BouncingLoaderWrapper>
          <BouncingLoaderDot />
          <BouncingLoaderDot />
          <BouncingLoaderDot />
        </BouncingLoaderWrapper>
      </StyledAILoadWrapper>
    );
  }

  return (
    <StyledHumanContentWrapper>
      {content?.images && content?.images.length > 0 && (
        <StyledHumanChatBox
          color={style?.human_text_color}
          bgColor={style?.human_bg_color}
        >
          {content.images.map((item, index) => (
            <StyledHumanImage src={URL.createObjectURL(item)} key={index} />
          ))}
        </StyledHumanChatBox>
      )}
      {content.message.length > 0 && (
        <StyledHumanChatBox
          color={style?.human_text_color}
          bgColor={style?.human_bg_color}
        >
          <div dangerouslySetInnerHTML={{ __html: content.message }} />
        </StyledHumanChatBox>
      )}
      {content?.audio && (
        <StyledHumanChatBox
          color={style?.human_text_color}
          bgColor={style?.human_bg_color}
        >
          <AudioPlayer src={URL.createObjectURL(content.audio)} />
        </StyledHumanChatBox>
      )}
    </StyledHumanContentWrapper>
  );
}

const StyledAIAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  align-self: flex-end;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const StyledChatBox = styled.div`
  border-radius: 8px;
  padding: 4px 12px;
  max-width: max-content;
  font-size: 16px;
  display: flex;
  align-items: start;
  height: max-content;
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const StyledAIChatBox = styled(StyledChatBox)`
  color: ${(props) => (props?.color ? props?.color : "white")};
  background: ${(props) => (props?.bgColor ? props?.bgColor : "darkgray")};
  align-self: self-start;
  flex: 1 0;
`;

const StyledAILoadWrapper = styled(StyledChatBox)`
  color: ${(props) => (props?.color ? props?.color : "white")};
  background: ${(props) => (props?.bgColor ? props?.bgColor : "darkgray")};
  align-self: self-start;
`;

const AIChatContent = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const StyledHumanContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 4px;
  width: 100%;
`;

const StyledHumanChatBox = styled(StyledChatBox)`
  color: ${(props) => (props?.color ? props?.color : "white")};
  background: ${(props) => (props?.bgColor ? props?.bgColor : "darkgray")};
  align-self: self-end;
  max-width: 80%;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const StyledHumanImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const bounceAnimation = keyframes`
  to {
    opacity: 0.1;
  }
`;

const BouncingLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8.5px auto;
`;

const BouncingLoaderDot = styled.div`
  width: 4px;
  height: 4px;
  margin: 1px 4px;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 1;
  animation: ${bounceAnimation} 0.6s infinite alternate;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;
