import config from "@/config";
import { post, get, deleteRequest, put } from "./base";

const { VENGOAI_ADMIN_API_BASE_URL } = config;
const url = `${VENGOAI_ADMIN_API_BASE_URL}/admin/identity_manage`;
const urls = `${VENGOAI_ADMIN_API_BASE_URL}/common`;

export default {
  get: () => get(`${urls}/get_identities`),
  create: (data) => post(`${url}/create`, data),
  update: (id, data) => put(`${url}/update/${id}`, data),
  delete: (data) => deleteRequest(`${url}/delete/${data}`),
};
