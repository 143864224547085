import React from "react";
import { HiPlayCircle, HiStopCircle } from "react-icons/hi2";

const PlayPauseIcon = ({ isPlaying, onClick }) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      {isPlaying ? <HiStopCircle size={24} /> : <HiPlayCircle size={24} />}
    </div>
  );
};

export default PlayPauseIcon;
