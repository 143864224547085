import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";

import styled from "styled-components";

const PersonasMonitorBox = styled.div`
  .chartjs-render-monitor {
    max-height: 300px;
    min-height: 300px;
  }
`;

const data = {
  labels: ["25", "26", "27", "28", "29", "30", "31"],
  datasets: [
    {
      label: "Sport",
      fill: false,
      lineTension: 0.3,
      backgroundColor: "#36A2EB",
      borderColor: "#36A2EB",
      borderWidth: 1,
      borderDash: [5, 3],
      pointBackgroundColor: "#36A2EB",
      pointHoverRadius: 3,
      pointHoverBorderWidth: 1,
      pointRadius: 2,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
    {
      label: "Music",
      fill: false,
      lineTension: 0.3,
      backgroundColor: "#FF6384",
      borderColor: "#FF6384",
      borderWidth: 1,
      borderDash: [3, 3],
      pointBackgroundColor: "#FF6384",
      pointHoverRadius: 2,
      pointHoverBorderWidth: 1,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [5, 7, 54, 32, 87, 45, 25],
    },
    {
      label: "Friend",
      fill: false,
      lineTension: 0.3,
      backgroundColor: "#FFCE56",
      borderColor: "#FFCE56",
      borderWidth: 1,
      pointBackgroundColor: "#FFCE56",
      pointHoverRadius: 5,
      pointHoverBorderWidth: 1,
      pointRadius: 4,
      pointHitRadius: 10,
      data: [25, 54, 2, 48, 78, 54, 35],
    },
    {
      label: "Book",
      fill: false,
      lineTension: 0.3,
      backgroundColor: "#4BC0C0",
      borderColor: "#4BC0C0",
      borderWidth: 1,
      pointBackgroundColor: "#4BC0C0",
      pointHoverRadius: 6,
      pointHoverBorderWidth: 1,
      pointRadius: 5,
      pointHitRadius: 10,
      data: [52, 47, 45, 4, 58, 7, 45],
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: "bottom",
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "rgb(204, 204, 204)",
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: "rgb(204, 204, 204)",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "rgb(204, 204, 204)",
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: "rgb(204, 204, 204)",
        },
      },
    ],
  },
};

const PersonasMonitor = () => {
  const { t } = useTranslation("common");

  return (
    <Col lg={6} xl={9} md={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{"Personas Monitor"}</CardTitle>
          </CardTitleWrap>
          <PersonasMonitorBox>
            <Line data={data} options={options} />
          </PersonasMonitorBox>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PersonasMonitor;
