import Slider from "rc-slider";
import React from "react";
import "rc-slider/assets/index.css";
import styled from "styled-components";

const ProgressBar = ({ duration, currentTime }) => {
  return (
    <div style={{ width: "100px", display: "flex", alignItems: "center" }}>
      <StyledSlider max={duration} value={currentTime} />
    </div>
  );
};

const StyledSlider = styled(Slider)`
  & .rc-slider-rail {
    background-color: gray;
  }
`;

export default ProgressBar;
