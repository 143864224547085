const BasicTableData = () => {
  const header = [
    { accessor: "id", Header: "#" },
    { accessor: "email", Header: "Email" },
    { accessor: "name", Header: "Name" },
    { accessor: "role", Header: "Role" },
    { accessor: "status", Header: "Status" },
    { accessor: "action", Header: "Action" },
  ];

  const basicTableData = {
    tableHeaderData: header,
  };
  return basicTableData;
};

export default BasicTableData;
