import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UsersMessageStatistics from "./components/UsersMessageStatistics";
import UsersMessage from "./components/UsersMessage";
import SocialMarketing from "../Dashboards/App/components/SocialMarketing";
import MessageStatisticsChart from "./components/MessageStatisticsChart";
import toast from "react-hot-toast";

const MessageMetrics = () => {
  const { t } = useTranslation("common");
  const [topMembers, setTopMembers] = useState([]);
  const [monthsMessagesCount, setMonthsMessagesCount] = useState([]);
  const [monthsMessages, setMonthsMessages] = useState([]);

  const handleFetchTopMembers = () => {
    let token = localStorage.getItem("token");
    try {
      fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/user_manage/top_message_member`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setTopMembers(res.data);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const handleFetchMonthsMessages = () => {
    let token = localStorage.getItem("token");
    try {
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/recent_months_messages`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
        .then((res) => res.json())
        .then((res) => {
          setMonthsMessagesCount(
            res.data.map((item) => item.total_message_count),
          );
          setMonthsMessages(
            res.data.map((item) =>
              new Date(item.month).toLocaleString("default", { month: "long" }),
            ),
          );
        });
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    handleFetchTopMembers();
    handleFetchMonthsMessages();
  }, []);

  return (
    <Container>
      <Row>
        <SocialMarketing topMembers={topMembers} />
        <MessageStatisticsChart
          monthsMessages={monthsMessages}
          monthsMessagesCount={monthsMessagesCount}
        />
      </Row>
      <Row>
        <UsersMessageStatistics cardTitle="Message Details" />
      </Row>
      <Row>
        <UsersMessage cardTitle="Messages" cardSubTitle="List of Messages" />
      </Row>
    </Container>
  );
};

export default MessageMetrics;
