const BasicTableData = () => {
  const header = [
    { id: 1, title: "#" },
    { id: 2, title: "Name" },
    { id: 3, title: "Total Messages" },
    { id: 4, title: "Daily Average" },
    { id: 5, title: "Last Message" },
  ];

  const headerResponsive = [
    { id: 1, title: "#" },
    { id: 2, title: "First Name" },
    { id: 3, title: "Last Name" },
    { id: 4, title: "Username" },
    { id: 5, title: "Age" },
    { id: 7, title: "Date" },
    { id: 8, title: "Location" },
    { id: 9, title: "Status" },
  ];

  const rows = [
    {
      id: 1,
      username: "JohnSmith456",
      total: "150",
      average: "5",
      date: "2023-12-25",
    },
    {
      id: 2,
      username: "JohnSmith456",
      total: "150",
      average: "5",
      date: "2023-12-25",
    },
    {
      id: 3,
      username: "JohnSmith456",
      total: "150",
      average: "5",
      date: "2023-12-25",
    },
    {
      id: 4,
      username: "JohnSmith456",
      total: "150",
      average: "5",
      date: "2023-12-25",
    },
    {
      id: 5,
      username: "JohnSmith456",
      total: "150",
      average: "5",
      date: "2023-12-25",
    },
    {
      id: 6,
      username: "JohnSmith456",
      total: "150",
      average: "5",
      date: "2023-12-25",
    },
    {
      id: 7,
      username: "JohnSmith456",
      total: "150",
      average: "5",
      date: "2023-12-25",
    },
  ];

  const basicTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
    tableRowsData: rows,
  };
  return basicTableData;
};

export default BasicTableData;
