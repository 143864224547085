import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "@/shared/components/ProgressBar";
import {
  DashboardSocialStatItem,
  DashboardSocialStatProgress,
  DashboardSocialStatTitle,
} from "../../BasicStatisticComponents";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { Col } from "react-bootstrap";

const SocialScore = ({ children, progress }) => (
  <DashboardSocialStatItem>
    <DashboardSocialStatTitle>{children}</DashboardSocialStatTitle>
    <DashboardSocialStatProgress wide>
      <ProgressBar
        top
        now={progress}
        label={`${progress}%`}
        size="small"
        gradient="blue"
        rounded
      />
    </DashboardSocialStatProgress>
  </DashboardSocialStatItem>
);

SocialScore.propTypes = {
  progress: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const SocialMarketing = ({ topMembers }) => {
  return (
    <Col lg={6} xl={3} xs={12} md={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{"Top Members"}</CardTitle>
          </CardTitleWrap>
          <div>
            {topMembers.map((item, index) => (
              <SocialScore
                key={index}
                progress={parseFloat(item.total_message_count.toFixed(2))}
              >
                {item.user_name}
              </SocialScore>
            ))}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SocialMarketing;
