import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Button } from "@/shared/components/Button";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import AccessData from "./AccessData";
import useFullLoading from "../../shared/hooks/store/useLoading";
import { Table } from "@/shared/components/TableElements";
import FileEditOutlineIcon from "mdi-react/FileEditOutlineIcon";
import TrashOutlineIcon from "mdi-react/TrashOutlineIcon";
import styled from "styled-components";
import EditItemModal from "./components/EditItemModal";
import toast from "react-hot-toast";
import DeleteModal from "../Members/components/DeleteModal";

const AccessControl = () => {
  const { tableHeaderData } = AccessData();
  const { tableHeaderItem } = AccessData();
  const { setLoading } = useFullLoading();
  const [accessList, setAccessList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState();
  const [role, setRole] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [disable, setDisable] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const getProfile = (url, method, token) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetchAccessList = useCallback(async () => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/role_manage/get_roles`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            setAccessList(res.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  }, [setLoading]);

  const handleDeleteRole = async (id) => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/role_manage/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === "success") {
            toast.success("success!");
            await handleFetchAccessList();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err);
    }
  };

  const activeColumn = useCallback(
    (data) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <FileEditOutlineIcon
          onClick={(e) => {
            if (disable.length)
              if (disable[13].update) {
                e.stopPropagation();
                setEditData(data);
                setOpenModal(true);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[13].update
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[13].update ? 1 : 0.5) : "",
          }}
          role="button"
        />
        <TrashOutlineIcon
          onClick={(e) => {
            if (disable.length)
              if (disable[13].delete) {
                e.stopPropagation();
                setOpenDeleteModal(true);
                setDeleteId(data.id);
              }
          }}
          style={{
            cursor: disable.length
              ? disable[13].delete
                ? "pointer"
                : "not-allowed"
              : "",
            opacity: disable.length ? (disable[13].delete ? 1 : 0.5) : "",
          }}
          role="button"
        />
      </div>
    ),
    [disable],
  );

  const tableData = useMemo(
    () =>
      accessList &&
      accessList.map((item, index) => ({
        ...item,
        no: index + 1,
        action: activeColumn(item),
      })),
    [accessList],
  );

  const handleSavePermission = useCallback(
    async (data) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/role_manage/set_role_permissions/${role}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ permissions: data }),
          },
        )
          .then((res) => res.json())
          .then(async (res) => {
            if (res.status === "success") {
              toast.success("success!");
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.error(err);
      }
    },
    [setLoading, role],
  );

  const tablePermissionData = useMemo(
    () =>
      roleList &&
      roleList.map((item) => ({
        ...item,
        read: (
          <StyledInput
            type="checkbox"
            checked={item.read}
            onChange={() => {
              let items = roleList.map((role) => {
                if (role.id === item.id) {
                  return { ...role, read: !item.read };
                }
                return role;
              });
              setRoleList(items);
            }}
          />
        ),
        write: (
          <StyledInput
            type="checkbox"
            checked={item.write}
            onChange={() => {
              let items = roleList.map((role) => {
                if (role.id === item.id) {
                  return { ...role, write: !item.write };
                }
                return role;
              });
              setRoleList(items);
            }}
          />
        ),
        update: (
          <StyledInput
            type="checkbox"
            checked={item.update}
            onChange={() => {
              let items = roleList.map((role) => {
                if (role.id === item.id) {
                  return { ...role, update: !item.update };
                }
                return role;
              });
              setRoleList(items);
            }}
          />
        ),
        delete: (
          <StyledInput
            type="checkbox"
            checked={item.delete}
            onChange={() => {
              let items = roleList.map((role) => {
                if (role.id === item.id) {
                  return { ...role, delete: !item.delete };
                }
                return role;
              });
              setRoleList(items);
            }}
          />
        ),
      })),
    [roleList],
  );

  const handleSaveRole = useCallback(
    async (data) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        editData
          ? fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/role_manage/update/${editData.id}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              },
            )
              .then((res) => res.json())
              .then(async (res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  await handleFetchAccessList();
                }
              })
              .finally(() => {
                setLoading(false);
              })
          : fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/role_manage/create`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              },
            )
              .then((res) => res.json())
              .then(async (res) => {
                if (res.status === "success") {
                  toast.success("success!");
                  await handleFetchAccessList();
                }
              })
              .finally(() => {
                setLoading(false);
              });
        setOpenModal(false);
        setEditData(null);
      } catch (err) {
        toast.error(err);
      }
    },
    [editData, handleFetchAccessList, setLoading],
  );

  const handleFetchPermission = useCallback(
    async (data) => {
      let token = localStorage.getItem("token");
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/role_manage/get_role_permissions/${data}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
          .then((res) => res.json())
          .then(async (res) => {
            if (res.status === "success") {
              setRoleList(res.data);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        toast.error(err);
      }
    },
    [setLoading],
  );

  useEffect(() => {
    handleFetchAccessList();
    getProfile(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/get_profile`,
      "GET",
      localStorage.getItem("token"),
    ).then((result) => {
      setDisable(result.data.role_permissions);
    });
  }, []);

  useEffect(() => {
    if (role) {
      handleFetchPermission(role);
    }
  }, [role]);

  return (
    <Container className="dashboard" fluid>
      <Row>
        <Col md={12} lg={12} xl={5}>
          <Card>
            <CardBody>
              <StyledHeader>
                <CardTitleWrap>
                  <CardTitle>Role</CardTitle>
                  <CardSubhead>List of Role</CardSubhead>
                </CardTitleWrap>
                <StyledButton
                  variant="primary"
                  onClick={() => {
                    if (disable.length)
                      if (disable[13].write) setOpenModal(true);
                  }}
                  style={{
                    cursor: disable.length
                      ? disable[13].write
                        ? "pointer"
                        : "not-allowed"
                      : "",
                    opacity: disable.length
                      ? disable[13].write
                        ? 1
                        : 0.5
                      : "",
                  }}
                >
                  Add Role
                </StyledButton>
              </StyledHeader>
              <Table responsive hover>
                <thead>
                  <tr>
                    {tableHeaderData.map((item) => (
                      <th key={item.id}>{item.Header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setRole(item.id);
                        }}
                      >
                        <td>{item.no}</td>
                        <td>{item.title}</td>
                        <td>{item.action}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md={12} lg={12} xl={7}>
          <Card>
            <CardBody>
              <StyledHeader>
                <CardTitleWrap>
                  <CardTitle>PERMISSIONS</CardTitle>
                  <CardSubhead>Permission according to role</CardSubhead>
                </CardTitleWrap>
                {roleList ? (
                  <StyledButton
                    variant="primary"
                    onClick={() => {
                      if (disable.length)
                        if (disable[13].update) handleSavePermission(roleList);
                    }}
                    style={{
                      cursor: disable.length
                        ? disable[13].update
                          ? "pointer"
                          : "not-allowed"
                        : "",
                      opacity: disable.length
                        ? disable[13].update
                          ? 1
                          : 0.5
                        : "",
                    }}
                  >
                    Save
                  </StyledButton>
                ) : (
                  <></>
                )}
              </StyledHeader>
              <Table responsive hover>
                <thead>
                  <tr>
                    {tableHeaderItem.map((item) => (
                      <th key={item.id}>{item.Header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tablePermissionData &&
                    tablePermissionData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td>{item.read}</td>
                        <td>{item.write}</td>
                        <td>{item.update}</td>
                        <td>{item.delete}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <EditItemModal
        title="Role"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
        onSubmit={handleSaveRole}
        editData={editData}
      />
      <DeleteModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteRole(deleteId);
        }}
        title={"Are you sure you want to delete this role?"}
      />
    </Container>
  );
};

const StyledButton = styled(Button)`
  width: max-content;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
  margin-bottom: 30px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled.input`
  height: 18px;
  width: 18px;
`;

export default AccessControl;
