import React, { useEffect, useState } from "react";
import Badge from "@/shared/components/Badge";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import BasicTableData from "./BasicTableData";
import styled from "styled-components";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";

const { historyHeader } = BasicTableData();

const PurchaseHistoryTable = ({ cardSubTitle, cardTitle }) => {
  const [history, setHistory] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageOption, setPageOption] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getHistory = () => {
    let token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/purchase_manage/get_purchase_history`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page_index: offset,
          show_count: pageSize,
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setHistory(res.data);
        setTotalCount(res.total);
      });
  };

  useEffect(() => {
    getHistory();
  }, []);

  useEffect(() => {
    setPageOption([
      ...Array(Math.floor((totalCount - 1) / pageSize) + 1).keys(),
    ]);
  }, [pageSize, totalCount]);

  return (
    <Col lg={6} xl={6} md={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{cardTitle}</CardTitle>
            <CardSubhead>{cardSubTitle}</CardSubhead>
          </CardTitleWrap>
          <Table responsive bordered>
            <thead>
              <tr>
                {historyHeader.map((item, index) => (
                  <th key={index}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.user_name}</td>
                  <td>${item.amount}</td>
                  <td>{item.description}</td>
                  <td>
                    <Badge bg={"primary"}>Success</Badge>
                  </td>
                  <td>
                    {new Date(item.created_at).toISOString().slice(0, 10)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationStyle>
            <ReactTablePagination
              page={history}
              gotoPage={(value) => {
                setOffset(value);
              }}
              previousPage={() => {
                setOffset(offset - 1);
              }}
              nextPage={() => {
                setOffset(offset + 1);
              }}
              canPreviousPage={offset > 0 ? true : false}
              canNextPage={
                offset === pageOption[pageOption.length - 1] ? false : true
              }
              pageOptions={pageOption}
              pageIndex={offset}
              pageSize={pageSize}
              setPageSize={setPageSize}
              manualPageSize={[10, 20, 30, 40]}
              dataLength={totalCount}
            />
          </PaginationStyle>
        </CardBody>
      </Card>
    </Col>
  );
};

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 14px;
`;

export default PurchaseHistoryTable;
