import { useState } from "react";
import styled from "styled-components";
import { IoMdCloseCircle } from "react-icons/io";

export default function FilePreviewIcon({ file, onFileRemove }) {
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  return (
    <StyledFilePreviewWrapper
      onMouseEnter={() => setShowCloseIcon(true)}
      onMouseLeave={() => setShowCloseIcon(false)}
    >
      <StyledPreviewImage
        src={URL.createObjectURL(file)}
        alt={file.name}
        width="50"
        height="50"
      />
      {showCloseIcon && (
        <StyledCloseIcon width={20} height={20} onClick={onFileRemove} />
      )}
    </StyledFilePreviewWrapper>
  );
}

const StyledFilePreviewWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
`;

const StyledPreviewImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const StyledCloseIcon = styled(IoMdCloseCircle)`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: white;
`;
