import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { renderComponentField } from "@/shared/components/form/FormField";
import { colorFieldsBorder, colorText } from "@/utils/palette";
import { paddingLeft } from "@/utils/directions";

export const FileInputField = React.forwardRef(
  ({ onChange, name, value = [] }, ref) => {
    const handleChange = (e) => {
      e.preventDefault();
      const files = [...e.target.files];
      let totalSize = files.reduce((sum, file) => sum + file.size, 0);

      if (totalSize > 100 * 1024 * 1024) {
        // Check if total size exceeds 100MB
        alert("Total file size should not exceed 100MB.");
        return;
      }

      const fileData = files.map((file) => ({
        file,
        name: file.name,
      }));

      onChange(fileData);
    };

    return (
      <FormGroupFile>
        <label htmlFor={name}>Choose the file</label>
        {value.length > 0 &&
          value.map((file, index) => (
            <span className="label-style" key={index}>
              {file.name}
            </span>
          ))}
        <input
          type="file"
          name={name}
          id={name}
          onChange={handleChange}
          ref={ref}
          multiple
        />
      </FormGroupFile>
    );
  },
);

FileInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

FileInputField.defaultProps = {
  value: [],
};

export default renderComponentField(FileInputField);

// region STYLES

const FormGroupFile = styled.div`
  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    border: 1px solid #b5b5c9;
    color: ${colorText};

    &:hover {
      background: ${colorFieldsBorder};
    }
  }

  span {
    ${paddingLeft}: 10px;
  }

  input {
    display: none;
  }
`;

// endregion
