import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserRate from "./components/UserRate";
import MostSetting from "./components/MostSetting";
import BehavioralInsight from "./components/BehavioralInsight";
import Surveys from "./components/Surveys";

const UserFeedBack = () => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <Row>
        <UserRate />
        <Surveys />
      </Row>
      <Row>
        <BehavioralInsight />
      </Row>
      <Row>
        <MostSetting />
      </Row>
    </Container>
  );
};

export default UserFeedBack;
