import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PopularPersonas from "./components/PopularPersonas";
import ChatbotAverageTime from "./components/ChatbotAverageTime";
import UserResponse from "./components/UserResponse";
import PersonasMonitor from "./components/PersonasMonitor";
import SystemMonitor from "./components/SystemMonitor";
import AnaylzePersonas from "./components/AnaylzePersonas";

const AiMetrics = () => {
  const { t } = useTranslation("common");
  const [popularPersonas, setPopularPersona] = useState([]);

  const getPopularPersonas = () => {
    let token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/persona_manage/user_purchase_stats`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setPopularPersona(res.data);
      });
  };

  useEffect(() => {
    getPopularPersonas();
  }, []);

  return (
    <Container>
      <Row>
        <PopularPersonas popularPersonas={popularPersonas} />
        <AnaylzePersonas />
      </Row>
      <Row>
        <ChatbotAverageTime
          cardTitle="Persona Performance"
          cardSubTitle="Average Response Time"
        />
        <UserResponse cardTitle="Member Response Time" />
      </Row>
      <Row>
        <SystemMonitor />
        <PersonasMonitor />
      </Row>
    </Container>
  );
};

export default AiMetrics;
